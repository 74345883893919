import React, { useEffect, useState } from "react";
import { Header } from "./shared/header/Header";
import { HomePage } from "./components/home-page/HomePage";
import { Footer } from "./shared/footer/Footer";
import { Navigate, Route, Routes } from "react-router-dom";
import { ConsultingPage } from "./components/consulting/ConsultingPage";
import { ResourcesPage } from "./components/resources/ResourcesPage";
import { AboutPage } from "./components/about/AboutPage";
import { PrivacyPolicyPage } from "./components/privacy-policy/PrivacyPolicyPage";
import { LegalDisclaimerPage } from "./components/legal-disclaimer/LegalDisclaimerPage";
import { ChainlinkNodeOperator } from "./components/consulting/sub-pages/chainlink-node-operator/ChainLinkOperator";
import { Web3Infrastructure } from "./components/consulting/sub-pages/web3-infrastructure/Web3Infrastructure";
import { Validator } from "./components/consulting/sub-pages/validator/Validator";
import { CookiePolicyPage } from "./components/cookie-policy/CookiePolicyPage";

export const App: React.FC = () => {
  const [loaded, setLoaded] = useState(true);
  const [windowHeight, setWindowHeight] = useState(document.body.clientHeight);

  useEffect(() => {
    if (windowHeight !== document.body.clientHeight) {
      setWindowHeight(document.body.clientHeight);
      setLoaded(true);
    }
  }, [windowHeight]);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      var element = document.getElementById(hash);
      element?.scrollIntoView();
    }
  });

  return (
    <>
      {loaded && (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />}></Route>
            <Route
              path="/web3-consultancy"
              element={<ConsultingPage />}
            ></Route>
            <Route
              path="/chainlink-node-operator-consulting"
              element={<ChainlinkNodeOperator />}
            ></Route>
            <Route
              path="/blockchain-validator-consulting"
              element={<Validator />}
            ></Route>
            <Route
              path="/web3-infrastructure-consulting"
              element={<Web3Infrastructure />}
            ></Route>
            <Route path="/resources" element={<ResourcesPage />}></Route>
            <Route path="/about" element={<AboutPage />}></Route>
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicyPage />}
            ></Route>
            <Route path="/cookie-policy" element={<CookiePolicyPage />}></Route>
            <Route
              path="/legal-disclaimer"
              element={<LegalDisclaimerPage />}
            ></Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
};

export default App;
