import React from "react";
import { useTheme } from "@mui/material/styles";
import { LandingSection } from "../../shared/landing-section/LandingSection";
import { HistorySection } from "./sections/HistorySection";
import { HiringSection } from "../../shared/hiring-section/HiringSection";
import { ContactSection } from "./sections/ContactSection";
import { AnimateComponentOnScroll } from "../../shared/animate-component-on-scroll/AnimateComponentOnScroll";
import Newsletter from "../../shared/newsletter-subscribe/Newsletter";
import MetaTags from "react-meta-tags";

interface AboutPageProps {}

export const AboutPage: React.FC<AboutPageProps> = () => {
  const { palette } = useTheme();

  return (
    <>
      <MetaTags>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://linkriver.io/about" />
      </MetaTags>
      <LandingSection
        title="About"
        subTitle="Get to know LinkRiver"
        gradient={palette.gradient4}
      />
      <HistorySection />
      {process.env.REACT_APP_HIRING === "true" && <HiringSection />}

      <ContactSection />

      <AnimateComponentOnScroll enterDirection="bottom">
        <Newsletter />
      </AnimateComponentOnScroll>
    </>
  );
};
