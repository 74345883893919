import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { BenefitsSegment } from "./benefits-segment/BenefitsSegment";
import { AnimateComponentOnScroll } from "../../../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";
import { MBenefitsSection } from "./MBenefitsSection";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mroot: {
    display: "none",

    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  title: {
    margin: "90px 0",
    color: "#012A4F",
    fontSize: "40px",
    fontWeight: 600,

    [theme.breakpoints.down("md")]: {
      fontSize: "38px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
    },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    margin: "90px 0",
  },
  button: {
    background: "linear-gradient(94.25deg, #DC5203 0%, #DC8903 110.52%)",
    borderRadius: "42.5px",
    textTransform: "none",
    color: theme.palette.common.white,
    fontSize: "30px",
    height: "72px",
    fontWeight: 500,
    padding: "0 60px",

    "&:hover": {
      background:
        "linear-gradient(97.51deg, #F88702 -0.88%, #FECE01 96.18%), #FFFFFF",
    },
  },
}));

interface BenefitsSectionProps {
  primaryBenefits: any[];
  secondaryBenefits: any[];
  buttonText?: string;
}

export const BenefitsSection: React.FC<BenefitsSectionProps> = ({
  primaryBenefits,
  secondaryBenefits,
  buttonText,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <Box className={classes.mroot}>
        <MBenefitsSection
          primaryBenefits={primaryBenefits}
          secondaryBenefits={secondaryBenefits}
          buttonText={buttonText}
        />
      </Box>
      <Box
        className={classes.root}
        sx={{
          backgroundImage: "url(/images/consulting-bg.png)",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h3 className={classes.title}>Your benefits</h3>
        <AnimateComponentOnScroll enterDirection="left">
          <BenefitsSegment left segments={primaryBenefits} />
        </AnimateComponentOnScroll>
        <Typography className={classes.title}>
          Believe it or not, the list goes on...
        </Typography>
        <AnimateComponentOnScroll enterDirection="right">
          <BenefitsSegment segments={secondaryBenefits} />
        </AnimateComponentOnScroll>
        <Box className={classes.buttonBox}>
          <Button
            className={classes.button}
            href={"https://esbrfymchb6.typeform.com/to/DYdD09Bt"}
            target="_blank"
            rel="noreferrer"
          >
            {buttonText ?? "Get started now"}
          </Button>
        </Box>
      </Box>
    </>
  );
};
