import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { SegmentProps } from "../HowItWorksSection";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "25%",

    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  image: {
    margin: "5vh 0",
    width: "80%",

    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  title: {
    marginBottom: "35px",
    color: "#012A4F",
    fontSize: "32px",
    fontWeight: "700",

    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  subTitle: {
    textAlign: "center",
    fontSize: "25px",

    [theme.breakpoints.down("md")]: {
      fontSize: "21px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },
}));

interface ImageSegmentProps {
  imageSegment: SegmentProps;
}

export const ImageSegment: React.FC<ImageSegmentProps> = ({ imageSegment }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <img
        className={classes.image}
        src={`/images/${imageSegment.image}.png`}
        alt="consulting"
      />
      <Typography className={classes.title}>{imageSegment.title}</Typography>
      <Typography className={classes.subTitle}>
        {imageSegment.subTitle}
      </Typography>
    </Box>
  );
};
