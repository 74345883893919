import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  logoLink: {
    display: "flex",
    alignContent: "center",
  },
  logo: {
    [theme.breakpoints.up("md")]: {
      width: "198px",
      height: "64px",
    },
    [theme.breakpoints.down("md")]: {
      width: "158px",
      height: "51px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "119px",
      height: "39px",
    },
  },
}));

interface FooterLogoProps {}

export const FooterLogo: React.FC<FooterLogoProps> = () => {
  const { classes } = useStyles();

  return (
    <a className={classes.logoLink} href="/">
      <img
        className={classes.logo}
        src="../images/lr-logo.png"
        alt={"app logo"}
      />
    </a>
  );
};
