/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import { AnimateComponentOnScroll } from "../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  achievementsSection: {
    background: theme.palette.background2,
    display: "flex",
    flexWrap: "wrap",
    margin: "10vh auto 0",
    justifyContent: "center",
    color: theme.palette.primary.contrastText,
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  subTitle: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    margin: "10vh auto 5vh auto",
    marginBottom: "1vh",
    [theme.breakpoints.down("md")]: {
      margin: "8vh auto 5vh auto",
    },
    [theme.breakpoints.up("xxxl")]: {
      fontSize: "50px",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    margin: "0 3vw 4vh 3vw",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.up("xxxl")]: {
      fontSize: "72px",
    },
  },
  statSection: {
    display: "flex",
    flexWrap: "nowrap",
    width: "80vw",
    justifyContent: "space-around",
    textAlign: "center",
    marginBottom: "10vh",
    [theme.breakpoints.down("md")]: {
      width: "94vw",
    },
  },
  statPortion: {
    width: "300px",
    [theme.breakpoints.up("xxxl")]: {
      width: "600px",
    },
  },
  statText: {
    [theme.breakpoints.up("xxxl")]: {
      fontSize: "42px",
    },
  },
}));

interface AchievementsSectionProps {}

export const AchievementsSection: React.FC<AchievementsSectionProps> = () => {
  const { classes } = useStyles();

  const delay = 1500;

  let blockchainIntegrationsTimer: any;
  let productsTimer: any;
  let transactionsTimer: any;

  const statistics = {
    blockchainIntegrations: 30,
    products: 7,
    transactions: 100,
  };

  const { blockchainIntegrations, products, transactions } = statistics;

  const blockchainIntegrationsTime = Math.floor(delay / blockchainIntegrations);
  const productsTime = delay / products;
  const transactionsTime = delay / transactions;

  const [startCounting, setStartCounting] = useState(false);
  const [blockchainIntegrationsCounter, setBlockchainIntegrations] =
    useState(0);
  const [productsCounter, setProducts] = useState(0);
  const [transactionsCounter, setTransactions] = useState(0);

  const getCounter = (
    timer: any,
    time: number,
    data: number,
    setData: (arg0: (prev: any) => any) => void,
    counter: number
  ) => {
    if (!startCounting) return;
    clearInterval(timer);
    timer = setInterval(() => {
      if (counter >= data) {
        clearInterval(timer);
        return;
      }
      setData((prev) => prev + 1);
    }, time);

    return () => clearInterval(timer);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (1600 <= window.pageYOffset && window.pageYOffset <= 2400) {
          setStartCounting(true);
        } else {
          setStartCounting(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    return getCounter(
      blockchainIntegrationsTimer,
      blockchainIntegrationsTime,
      blockchainIntegrations,
      setBlockchainIntegrations,
      blockchainIntegrationsCounter
    );
  }, [blockchainIntegrationsCounter, startCounting]);

  useEffect(() => {
    return getCounter(
      productsTimer,
      productsTime,
      products,
      setProducts,
      productsCounter
    );
  }, [productsCounter, startCounting]);

  useEffect(() => {
    return getCounter(
      transactionsTimer,
      transactionsTime,
      transactions,
      setTransactions,
      transactionsCounter
    );
  }, [transactionsCounter, startCounting]);

  return (
    <AnimateComponentOnScroll enterDirection="bottom">
      <div className={classes.achievementsSection}>
        <Typography className={classes.subTitle} variant={"subtitle1"}>
          Achievements
        </Typography>
        <Typography className={classes.title} variant={"h3"}>
          Our contribution to an increasingly decentralized world
        </Typography>
        <div className={classes.statSection}>
          <div className={classes.statPortion}>
            <Typography variant={"statNumber1"}>
              {blockchainIntegrationsCounter}
            </Typography>
            <Typography className={classes.statText} variant={"body1"}>
              Different blockchain integrations
            </Typography>
          </div>
          <div className={classes.statPortion}>
            <Typography variant={"statNumber1"}>{productsCounter}</Typography>
            <Typography className={classes.statText} variant={"body1"}>
              Chainlink products secured
            </Typography>
          </div>
          <div className={classes.statPortion}>
            <Typography
              variant={"statNumber1"}
            >{`${transactionsCounter}M +`}</Typography>
            <Typography className={classes.statText} variant={"body1"}>
              On-chain transactions
            </Typography>
          </div>
        </div>
      </div>
    </AnimateComponentOnScroll>
  );
};
