import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Scrollbars } from "react-custom-scrollbars";

const useStyles = makeStyles<{ height: string }>()((theme, { height }) => ({
  slider: {
    display: "flex",
    gap: "40px",

    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      gap: "20px",
    },
  },
  renderView: { height: height },
  renderTrackHorizontal: {
    inset: "0px",
    top: "99%",
    backgroundColor: "#CCC",
    height: "2px !important",
  },
  renderThumbHorizontal: {
    backgroundColor: "black",
    height: "2px !important",
  },
  isDisabled: {
    display: "flex",
    justifyContent: "center",
    gap: 40,
  },
}));

interface SliderProps {
  items: JSX.Element;
  height: string;
  id: string;
  isDisabled?: boolean;
  forceDisable?: boolean;
}

export const Slider: React.FC<SliderProps> = ({
  items,
  height,
  id,
  isDisabled,
  forceDisable,
}) => {
  const { classes } = useStyles({ height });
  const [disabled, setDisabled] = useState(isDisabled);

  useEffect(() => {
    const element = document.querySelector(`#${id}`);

    if (element && items.props.children.length > 2) {
      element.addEventListener("wheel", (event: any) => {
        event.preventDefault();

        element.scrollBy({
          left: event.deltaY < 0 ? -20 : 20,
        });
      });
    }
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200 && !forceDisable) {
        setDisabled(false);
      } else {
        setDisabled(isDisabled);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return disabled ? (
    <div className={classes.isDisabled}>{items}</div>
  ) : (
    <Scrollbars
      autoHeight
      autoHeightMax={800}
      renderView={({ style }: any) => (
        <div id={id} className={classes.renderView} style={style} />
      )}
      renderTrackHorizontal={({ style }: any) => (
        <div className={classes.renderTrackHorizontal} style={style} />
      )}
      renderThumbHorizontal={({ style }: any) => (
        <div className={classes.renderThumbHorizontal} style={style} />
      )}
    >
      <div className={classes.slider}>{items}</div>
    </Scrollbars>
  );
};
