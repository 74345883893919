import React from "react";
import { makeStyles } from "tss-react/mui";
import SwipeableViews from "react-swipeable-views";
import { Box, Button, MobileStepper, Typography } from "@mui/material";
import { BenefitsSegment } from "./benefits-segment/BenefitsSegment";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",

    "& > div:first-of-type": {
      width: "100%",
    },

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  title: {
    marginBottom: "5vh",

    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
  },
  stepper: {
    background: "transparent",
    display: "flex",
    justifyContent: "center",
    marginTop: "5vh",
  },
  text: {
    color: "#949A9F",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    margin: "5vh 0 10vh",
  },
  button: {
    background: "linear-gradient(94.25deg, #DC5203 0%, #DC8903 110.52%)",
    borderRadius: "42.5px",
    textTransform: "none",
    color: theme.palette.common.white,
    fontSize: "36px",
    height: "72px",
    fontWeight: 500,
    padding: "0 60px",

    "&:hover": {
      background:
        "linear-gradient(97.51deg, #F88702 -0.88%, #FECE01 96.18%), #FFFFFF",
    },

    [theme.breakpoints.down("md")]: {
      fontSize: "23px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
}));

interface MBenefitsSectionProps {
  primaryBenefits: any[];
  secondaryBenefits: any[];
  buttonText?: string;
}

function splitToNChunks(array: any[], n: any) {
  let result = [];
  for (let i = n; i > 0; i--) {
    result.push(array.splice(0, 3));
  }
  return result;
}

export const MBenefitsSection: React.FC<MBenefitsSectionProps> = ({
  primaryBenefits,
  secondaryBenefits,
  buttonText,
}) => {
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const allBenefits = [...primaryBenefits, ...secondaryBenefits];
  const maxSteps = Math.ceil(allBenefits.length / 3);
  const chunkedBenefits = splitToNChunks(allBenefits, maxSteps);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box
      className={classes.root}
      sx={{
        backgroundImage: "url(/images/consulting-mbg.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "110%",
      }}
    >
      <Typography className={classes.title}>Your benefits</Typography>
      <SwipeableViews
        axis="x"
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {chunkedBenefits.map((chunk) => (
          <BenefitsSegment segments={chunk} isMobile />
        ))}
      </SwipeableViews>
      <MobileStepper
        className={classes.stepper}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={null}
        backButton={null}
      />
      <Typography className={classes.text}>Swipe for more benefits</Typography>
      <Box className={classes.buttonBox}>
        <Button
          className={classes.button}
          href={"https://esbrfymchb6.typeform.com/to/DYdD09Bt"}
          target="_blank"
          rel="noreferrer"
        >
          {buttonText ?? "Get started now"}
        </Button>
      </Box>
    </Box>
  );
};
