import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Collapse, List, ListItemButton, Typography } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles()((theme) => ({
  listTitle: {
    borderBottom: "1px solid #ADB3B9",
    justifyContent: "space-between",
    padding: "8px 0",
  },
  drawerList: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
}));

interface DrawerListProps {
  title: string;
  children: React.ReactNode;
}

export const DrawerList: React.FC<DrawerListProps> = ({ title, children }) => {
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton
        className={classes.listTitle}
        onClick={() => setOpen(!open)}
      >
        <Typography variant="subtitle4">{title}</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className={classes.drawerList} component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};
