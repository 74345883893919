import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { SubPageSegment } from "./sub-page-segment/SubPageSegment";

const useStyles = makeStyles()((theme) => ({
  subPageSection: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
    marginBottom: "150px",

    [theme.breakpoints.down("lg")]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
}));

interface SubPageSelectionProps {}

export const SubPageSelection: React.FC<SubPageSelectionProps> = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.subPageSection}>
      <SubPageSegment
        title="Chainlink Node Operators"
        description="Do you want to boost your performance?"
        link="/chainlink-node-operator-consulting"
      ></SubPageSegment>
      <SubPageSegment
        title="Validators"
        description="Do you want to become a Chainlink node operator?"
        link="/blockchain-validator-consulting"
      ></SubPageSegment>
      <SubPageSegment
        title="Everyone Else"
        description="Do you want to enter the Web3 infrastructure business?"
        link="/web3-infrastructure-consulting"
      ></SubPageSegment>
    </Box>
  );
};
