import React from "react";
import { useTheme } from "@mui/material/styles";
import { LandingSection } from "../../../../shared/landing-section/LandingSection";
import { HowItWorksSection } from "../sections/how-it-works-section/HowItWorksSection";
import { BenefitsSection } from "../sections/benefits-section/BenefitsSection";
import { ContributorsSection } from "../sections/contributors-section/ContributorsSection";
import MetaTags from "react-meta-tags";

interface ChainlinkNodeOperatorProps {}

export const ChainlinkNodeOperator: React.FC<
  ChainlinkNodeOperatorProps
> = () => {
  const { palette } = useTheme();
  const gradient = palette.gradient3;

  return (
    <>
      <MetaTags>
        <title>Safer sailing: Chainlink node operator consulting</title>
        <meta
          name="description"
          content="Bring key business areas to the highest level with our tailored consulting service to stand out among verified Chainlink node operators."
        />
        <meta name="robots" content="index, follow" />
        <meta name="robots" content="max-image-preview:large" />
        <link
          rel="canonical"
          href="https://linkriver.io/chainlink-node-operator-consulting"
        />
      </MetaTags>
      <LandingSection
        gradient={gradient}
        title="Let’s get all your ducks in a row"
        subTitle="Bring and maintain key business areas to the highest level to stand out among verified Chainlink node operators."
        height="70vh"
      ></LandingSection>
      <HowItWorksSection
        description="We provide solutions for all Chainlink node operator pain points and love to share them with you."
        first={{
          image: "consulting-clo-first",
          title: "Check In",
          subTitle:
            "You tell us your problem so we can see the bigger picture.",
        }}
        second={{
          image: "consulting-clo-second",
          title: "Analysis",
          subTitle: "We triage the issue to identify critical vulnerabilities.",
        }}
        third={{
          image: "consulting-clo-third",
          title: "Solution",
          subTitle: "Together we solve your concern sustainably.",
        }}
      />
      <BenefitsSection
        buttonText="Cast off"
        primaryBenefits={[
          <>
            Gaining more reputation, maximizing chances for new network and
            service integrations, jobs and ultimately{" "}
            <strong>
              boosting the revenue of your Chainlink node operation
            </strong>
          </>,
          <>
            <strong>Protection from being outperformed</strong> by enhancing
            your communication and internal workflows with and towards Chainlink
          </>,
          <>
            <strong>Not losing control of your finances</strong> by accurately
            tracking, analyzing and optimizing your income and expenses
          </>,
          <>
            <strong>Avoidance of nerve-wracking debugging sessions</strong> by
            adopting cutting edge technical improvements after undergoing our
            infrastructure analysis
          </>,
          <>
            Never again put yourself through the hustle of finding trustworthy,
            top-trained team members to{" "}
            <strong>
              avoid being solely responsible for your growing infrastructure
            </strong>
          </>,
          <>
            <strong>Improving your daily operations</strong> regarding internal
            wallet management, backup funding and escalation plans
          </>,
        ]}
        secondaryBenefits={[
          <>
            <strong>Ensuring your company's long-term existence</strong> by
            being prepared for sudden regulatory changes that may threaten its
            compliance
          </>,
          <>
            Strategies to{" "}
            <strong>protect yourself against adverse market conditions</strong>{" "}
            and illiquidity developed by our dedicated treasury manager
          </>,
          <>
            <strong>Never again fear additional scaling costs</strong> by
            developing a strategy to safely migrate to another hosting
            environment
          </>,
          <>
            <strong>Shield your nodes against cybersecurity threads,</strong>{" "}
            electricity and internet outages and implement strategies to achieve
            advanced geographic distribution
          </>,
          <>
            Predictions on how to use Chainlink Staking and other shifting
            reputational key metrics for your advantage{" "}
            <strong>to not become the jobless veteran soon</strong>
          </>,
        ]}
      />
      <ContributorsSection />
    </>
  );
};
