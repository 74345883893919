import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { AnimateComponentOnScroll } from "../../../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "#FBFBFF",
    padding: "0 10vw 5vh",
  },
  title: {
    width: "100%",
    marginTop: "10vh",
    color: "#012A4F",
    textAlign: "center",
    fontSize: "40px",
    fontWeight: 600,

    [theme.breakpoints.down("md")]: {
      fontSize: "38px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
    },
  },
  segmentBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "50px",
    margin: "5vh 0",

    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      gap: "0",
    },
  },
  imageLink: {
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  fullWidth: {
    display: "flex",
    justifyContent: "center",
    width: "100%",

    "& > img": {
      width: "70%",
    },
  },
  image: {
    width: "80%",
  },
}));

interface LogoData {
  logo: string;
  link: string;
}

interface LogoSectionProps {
  data: LogoData[];
  fullWidth?: boolean;
}

export const LogoSection: React.FC<LogoSectionProps> = ({
  data,
  fullWidth,
}) => {
  const { classes } = useStyles();

  return (
    <AnimateComponentOnScroll enterDirection="bottom">
      <Box className={classes.root}>
        <h3 className={classes.title}>
          Who is already aboard as a Chainlink node operator
        </h3>
        <Box className={classes.segmentBox}>
          {data.map((data) => {
            return (
              <a
                href={data.link}
                target="_blank"
                rel="noreferrer"
                className={fullWidth ? classes.fullWidth : classes.imageLink}
              >
                <img
                  src={`/images/${data.logo}.png`}
                  className={classes.image}
                  onMouseOver={(e) =>
                    (e.currentTarget.src = `/images/${data.logo}-color.png`)
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.src = `/images/${data.logo}.png`)
                  }
                  alt="logo"
                />
              </a>
            );
          })}
        </Box>
      </Box>
    </AnimateComponentOnScroll>
  );
};
