import React from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  hiringSection: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    color: theme.palette.primary.contrastText,
    height: "100px",
    background: "linear-gradient(141deg,#3278be,#41b496)",
    backgroundSize: "120% 120%",
    animation: "gradient-animation 6s ease infinite",
    "@keyframes gradient-animation": {
      "0%": {
        backgroundPosition: "0% 50%",
      },
      "50%": {
        backgroundPosition: "100% 50%",
      },
      "100%": {
        backgroundPosition: "0% 50%",
      },
    },
    "& a": {
      color: theme.palette.primary.contrastText,
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "baseline",
      alignContent: "center",
      flexWrap: "wrap",
    },
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "15px",
    },
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  link: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
    },
  },
}));

interface HiringSectionProps {}

export const HiringSection: React.FC<HiringSectionProps> = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.hiringSection}>
      <Typography
        className={classes.title}
        sx={{ typography: { sm: "h6", xs: "body1" } }}
      >
        We’re hiring!
      </Typography>
      <Typography
        className={classes.text}
        sx={{ typography: { sm: "h5", xs: "body5" } }}
      >
        Would you like to make history with us?{" "}
        <a
          href="https://www.linkedin.com/company/linkriver/"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          Check out our open positions
        </a>
      </Typography>
    </div>
  );
};
