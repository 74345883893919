import React, { ReactNode, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles<{ yearColor: string; backgroundColor: string }>()(
  (theme, { yearColor, backgroundColor }, classes) => ({
    historySegment: {
      backgroundColor: backgroundColor,
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "10px",
      borderRadius: "30px",
    },
    segment: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      width: "100%",
      height: "300px",
      [theme.breakpoints.down("sm")]: {
        height: "150px",
      },
    },
    title: {
      display: "flex",
      width: "100%",
      paddingBottom: "6px",
    },
    year: {
      transform: "rotate(-90deg)",
      padding: "0 70px",
      backgroundColor: yearColor,
      borderRadius: "41px",
      margin: "0 -3vh 0 -27px",
      color: theme.palette.primary.contrastText,
      [theme.breakpoints.down("sm")]: {
        padding: "0 40px",
      },
    },
    icon: {
      border: `1px solid ${yearColor}`,
      borderRadius: "5px",
      color: yearColor,
      width: "50px",
      height: "50px",
      marginRight: "100px",
      [theme.breakpoints.down("sm")]: {
        border: "0",
        marginRight: "10px",
      },
    },
  })
);

interface HistorySegmentProps {
  year: string;
  title: string;
  yearColor: string;
  backgroundColor: string;
  children: ReactNode;
}

export const HistorySegment: React.FC<HistorySegmentProps> = ({
  year,
  title,
  yearColor,
  backgroundColor,
  children,
}) => {
  const { classes } = useStyles({ yearColor, backgroundColor });
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={classes.historySegment} onClick={() => setOpen(!open)}>
      <div className={classes.segment}>
        <div className={classes.year}>
          <Typography
            sx={{ typography: { sm: "h4", xs: "subtitle4", color: yearColor } }}
          >
            {year}
          </Typography>
        </div>
        <Typography
          className={classes.title}
          sx={{ typography: { sm: "h9", xs: "h7" } }}
        >
          {title}
        </Typography>
        {open ? (
          <KeyboardArrowUpIcon className={classes.icon} />
        ) : (
          <KeyboardArrowDownIcon className={classes.icon} />
        )}
      </div>
      {open && children}
    </div>
  );
};
