import React, { ReactNode } from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  sliderItem: {
    textDecoration: "none",
    width: "350px",
    color: "#000",

    "&:hover": {
      filter: "brightness(80%)",
      "& span": {
        fontWeight: "700",
      },
      "& p": {
        fontWeight: "700",
      },
    },
  },
  researchSliderItem: {
    textDecoration: "none",
    color: "#000",

    "&:hover": {
      filter: "brightness(80%)",

      "& > p": {
        fontWeight: "700",
      },
    },
  },
  sliderItemImageTitle: {
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    color: "#000",
    minWidth: "300px",
    maxWidth: "400px",
    height: "228px",

    [theme.breakpoints.down("md")]: {
      maxWidth: "350px",
    },
  },
  imageBorder: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "228px",
    border: "1px solid #393E3530",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",

    "&:hover": {
      background: "#DDD",
    },
  },
  image: {
    alignSelf: "center",
    borderRadius: "20px",

    [theme.breakpoints.down("md")]: {
      width: "350px",
    },
  },
  imageText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20%",
    width: "80%",
    textAlign: "center",
    color: "#012A4F",
  },
  title: {
    margin: "35px 5% 0",
    textAlign: "center",
  },
  research: {
    height: "35vh",
    paddingTop: "10px",
    borderRadius: "14px",

    "&:hover": {
      fontWeight: "700",
    },

    [theme.breakpoints.down("sm")]: {
      height: "25vh",
    },
  },
  researchBorder: {
    display: "flex",
    justifyContent: "center",
    border: "1px solid #393E3530",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    background: "#FFF",
  },
}));

interface SliderItemProps {
  title?: string | ReactNode;
  imageTitle?: string;
  image?: string;
  showBorder?: boolean;
  link?: string;
}

export const SliderItem: React.FC<SliderItemProps> = ({
  title,
  imageTitle,
  image,
  showBorder,
  link,
}) => {
  const { classes } = useStyles();

  const getImageClass = (): string => {
    if (image === "research") {
      return classes.research;
    }
    return classes.image;
  };

  return (
    <a
      className={
        imageTitle
          ? classes.sliderItemImageTitle
          : image === "research"
          ? classes.researchSliderItem
          : classes.sliderItem
      }
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {showBorder ? (
        <div
          className={
            image === "research" ? classes.researchBorder : classes.imageBorder
          }
        >
          {!imageTitle ? (
            <img
              className={getImageClass()}
              src={`../images/${image}.png`}
              alt="resources"
            ></img>
          ) : (
            <Typography
              className={classes.imageText}
              sx={{ typography: { sm: "h5", xs: "body5" } }}
            >
              {imageTitle}
            </Typography>
          )}
        </div>
      ) : !imageTitle ? (
        <img
          className={classes.image}
          src={`../images/${image}.png`}
          alt="resources"
        ></img>
      ) : (
        <Typography className={classes.imageText} variant="h5">
          {imageTitle}
        </Typography>
      )}
      {typeof title === "string" ? (
        <Typography
          className={classes.title}
          sx={{ typography: { sm: "subtitle2", xs: "body2" } }}
        >
          {title}
        </Typography>
      ) : (
        title
      )}
    </a>
  );
};
