import React from "react";
import { makeStyles } from "tss-react/mui";
import { Toolbar } from "@mui/material";
import { HeaderDrawer } from "./header-drawer/HeaderDrawer";
import { HeaderLogo } from "./header-logo/HeaderLogo";
import { HeaderDropdown } from "./header-dropdown/HeaderDropdown";

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    height: "10vh",
    justifyContent: "space-between",
    marginLeft: "2vw",
    right: "none",

    [theme.breakpoints.down("sm")]: {
      height: "8vh",
    },
  },
  dropdown: {
    display: "flex",
    justifyContent: "space-around",
    width: "50%",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  drawer: {
    display: "flex",
    justifyContent: "end",
    width: "50%",

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

interface HeaderToolbarProps {
  transparent: boolean;
}

export const HeaderToolbar: React.FC<HeaderToolbarProps> = ({
  transparent,
}) => {
  const { classes } = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <HeaderLogo transparent={transparent} />
      <div className={classes.dropdown}>
        <HeaderDropdown transparent={transparent} />
      </div>
      <div className={classes.drawer}>
        <HeaderDrawer transparent={transparent} />
      </div>
    </Toolbar>
  );
};
