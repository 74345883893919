import React from "react";
import { LandingSection } from "../../shared/landing-section/LandingSection";
import { MissionSection } from "./mission-section/MissionSection";
import { AchievementsSection } from "./achievements-section/AchievementSection";
import { ServiceSection } from "./service-section/ServiceSection";
import { useTheme } from "@mui/material/styles";
import { MissionSectionM } from "./mission-section/MissionSectionM";
import { AchievementsSectionM } from "./achievements-section/AchievementSectionM";
import { ServiceSectionM } from "./service-section/ServiceSectionM";
import { AnimateComponentOnScroll } from "../../shared/animate-component-on-scroll/AnimateComponentOnScroll";
import { Box } from "@mui/material";
import Newsletter from "../../shared/newsletter-subscribe/Newsletter";
import MetaTags from "react-meta-tags";

interface HomePageProps {}

export const HomePage: React.FC<HomePageProps> = () => {
  const { palette } = useTheme();

  return (
    <Box sx={{ overflow: "hidden", height: "100%" }}>
      <MetaTags>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://linkriver.io" />
      </MetaTags>
      <LandingSection
        title="Top-Tier Chainlink Node Operator"
        subTitle="Decentralized data flow for a transparent world"
        gradient={palette.gradient1}
      />
      <MissionSection />
      <MissionSectionM />
      <AchievementsSection />
      <AchievementsSectionM />
      <ServiceSection />
      <ServiceSectionM />
      <AnimateComponentOnScroll enterDirection="bottom">
        <Newsletter />
      </AnimateComponentOnScroll>
    </Box>
  );
};
