import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { LandingSection } from "../../../../shared/landing-section/LandingSection";
import { HowItWorksSection } from "../sections/how-it-works-section/HowItWorksSection";
import { BenefitsSection } from "../sections/benefits-section/BenefitsSection";
import { ContributorsSection } from "../sections/contributors-section/ContributorsSection";
import { LogoSection } from "../sections/logo-section/LogoSection";
import MetaTags from "react-meta-tags";

interface Web3InfrastructureProps {}

export const Web3Infrastructure: React.FC<Web3InfrastructureProps> = () => {
  const { palette } = useTheme();
  const gradient = palette.gradient3;

  return (
    <>
      <MetaTags>
        <title>
          Innovate your company with our Web3 infrastructure consulting
        </title>
        <meta
          name="description"
          content="What if you could have invested in the internet when it was born? Do it now: enter the Web3 infrastructure business and profit from our consultancy."
        />
        <meta name="robots" content="index, follow" />
        <meta name="robots" content="max-image-preview:large" />
        <link
          rel="canonical"
          href="https://linkriver.io/web3-infrastructure-consulting"
        />
      </MetaTags>
      <LandingSection
        gradient={gradient}
        title="Let’s innovate your company: 
        Web3 infrastructure"
        subTitle="What if you could have invested in the internet when it was born? 
        Just do it now, by entering the save haven of Web3 infrastructure to stay fully competitive."
        height="70vh"
      ></LandingSection>
      <Box>
        <HowItWorksSection
          description="Web3 is complex but we mastered it and love to share our most promising strategy to jump on the accelerating bandwagon: Chainlink node operations."
          first={{
            image: "consulting-ee-first",
            title: "Introduction",
            subTitle: "We warmly welcome you to the Chainlink community.",
          }}
          second={{
            image: "consulting-ee-second",
            title: "Guidance",
            subTitle:
              "Step by step we provide you with all necessary information.",
          }}
          third={{
            image: "consulting-ee-third",
            title: "Execution",
            subTitle: "Together we sort out clear milestones for your journey.",
          }}
        />
        <BenefitsSection
          primaryBenefits={[
            <>
              <strong>
                Not missing out on the most significant industrial revolution of
                all time
              </strong>{" "}
              by learning to actively shape the Web3 with safe business choices
              and true pioneers by your side
            </>,
            <>
              <strong>Avoiding to be edged out by even more competitors</strong>{" "}
              by distinguishing yourself through innovation and claiming your
              future profits
            </>,
            <>
              <strong>Reducing the risk of decreasing profit margins</strong>{" "}
              due to unforeseeable geopolitical and macroeconomic trends by
              positioning yourself anti-fragile
            </>,
            <>
              <strong>
                Preventing the loss of the company's attractiveness
              </strong>{" "}
              to young high-potentials by offering the opportunity to
              participate in the development of cutting-edge technologies
            </>,
            <>
              <strong>
                No more aimlessly standing in front of the ocean of information
                about Web3
              </strong>{" "}
              by learning to understand its basics and niches in a structured
              way
            </>,
          ]}
          secondaryBenefits={[
            <>
              Getting first-hand information on why{" "}
              <strong>
                Chainlink is the most crucial project for the fourth industrial
                revolution
              </strong>{" "}
              and why you have to be part of it as a a node operator to not miss
              the party
            </>,
            <>
              <strong>
                Not struggling with the complex technical requirements
              </strong>{" "}
              and deployments of Chainlink node infrastructure by getting a
              comprehensible introduction from a top-tier node operator
            </>,
            <>
              <strong>
                Gain clarity about the human and financial resources
              </strong>{" "}
              it takes to succeed as a Chainlink node operator by learning from
              experts who went 0 to 1
            </>,
            <>
              No more fishing in muddy waters trying to{" "}
              <strong>
                figure out if now is the right time to conquer the Web3 space
              </strong>{" "}
              by gaining access to our market assessments
            </>,
            <>
              <strong>Being introduced to how to get started</strong> and
              benefiting from our extensive network of contacts
            </>,
          ]}
        />
        <LogoSection
          fullWidth
          data={[
            {
              logo: "consulting-ee-logo-first",
              link: "https://www.lexisnexis.com/",
            },
            {
              logo: "consulting-ee-logo-second",
              link: "https://www.t-systems.com/",
            },
            {
              logo: "consulting-ee-logo-third",
              link: "https://www.swisscom.ch/",
            },
          ]}
        />
        <ContributorsSection background="white" />
      </Box>
    </>
  );
};
