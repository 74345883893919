import React, {
  useState,
  useRef,
  useEffect,
  CSSProperties,
  ReactElement,
} from "react";

interface AnimateComponentOnScrollProps {
  enterDirection: "left" | "right" | "bottom" | "top";
  children: ReactElement;
  width?: string;
  display?: string;
  justifyContent?: string;
}

export const AnimateComponentOnScroll: React.FC<
  AnimateComponentOnScrollProps
> = ({
  enterDirection,
  children,
  width,
  display = "",
  justifyContent = "",
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const animatedComponentRef = useRef<HTMLDivElement>(null);

  const handleScroll = (): void => {
    if (!animatedComponentRef.current) return;

    let entryHeight;

    const { top } = animatedComponentRef.current.getBoundingClientRect();

    if (enterDirection === "bottom") {
      entryHeight = top - animatedComponentRef.current.clientHeight;
    } else {
      entryHeight = top;
    }

    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    if (entryHeight < windowHeight) {
      setIsVisible(true);
      window.removeEventListener("scroll", handleScroll);
    }
  };

  const getEnterDirectionStyle = (): string => {
    switch (enterDirection) {
      case "left":
        return "translateX(-100%)";
      case "right":
        return "translateX(100%)";
      case "bottom":
        return "translateY(100%)";
      case "top":
        return "translateY(-100%)";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const animatedComponentStyles: CSSProperties = {
    width: width ?? "inherit",
    opacity: 0,
    transform: getEnterDirectionStyle(),
    transition: "opacity 2s, transform 1.5s",
    display: display,
    justifyContent: justifyContent,
  };

  if (isVisible) {
    animatedComponentStyles.opacity = 1;
    animatedComponentStyles.transform = "translate(0, 0)";
  }

  return window.innerWidth < 600 ? (
    <>{children}</>
  ) : (
    <div style={animatedComponentStyles} ref={animatedComponentRef}>
      {children}
    </div>
  );
};
