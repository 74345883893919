import React from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItem,
  Typography,
} from "@mui/material";

interface DrawerItemProps {
  link: string;
  title?: string;
  target?: string;
  className?: string;
  children: React.ReactNode;
}

export const DrawerItem: React.FC<DrawerItemProps> = ({
  link,
  title,
  target,
  children,
}) => {
  return (
    <ListItem disablePadding>
      <ListItemButton type="button" href={link} target={target ?? "_self"}>
        <ListItemIcon>{children}</ListItemIcon>
        <Typography variant="body6">{title}</Typography>
      </ListItemButton>
    </ListItem>
  );
};
