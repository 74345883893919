import React from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  list: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    width: "100%",
    margin: "20px",
    paddingLeft: "5vw",

    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
    },
  },
  itemHeader: {
    marginBottom: "20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
    [theme.breakpoints.up("xxxl")]: {
      fontSize: "40px",
    },
  },
  linkheader: {
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
  },
}));

interface FooterListProps {
  title: string;
  link: string;
  children: React.ReactNode;
  target?: string;
  small?: boolean;
}

export const FooterList: React.FC<FooterListProps> = ({
  title,
  link,
  target,
  children,
  small,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.list}>
      <Typography
        className={classes.itemHeader}
        variant={small ? "body5" : "body1"}
      >
        <a
          className={classes.linkheader}
          href={link}
          target={target ?? "_self"}
        >
          {title}
        </a>
      </Typography>
      {children}
    </div>
  );
};
