import { Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { BlogSegmentFooter } from "./blog-segment-footer/BlogSegmentFooter";
import { MBlogSegmentFooter } from "./blog-segment-footer/MBlogSegmentFooter";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    width: "400px",
    color: "#000",
    textDecoration: "none",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.down("lg")]: {
      width: "60%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  upperPart: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "400px",

    "&:hover": {
      "& > img:first-of-type": {
        filter: "brightness(80%)",
      },
      "& > p:first-of-type": {
        color: "#3278BE",
      },
    },
  },
  image: {
    width: "100%",
    borderRadius: "15px",
  },
  title: {
    fontSize: "23px",
    fontWeight: 700,
    marginBottom: "20px",
    height: "65px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    margin: "25px 0 10px",
    lineHeight: "1.3  !important",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",

    [theme.breakpoints.up("md")]: {
      fontSize: "25px",
      fontWeight: 700,
      height: "97.5px",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      fontWeight: 700,
      height: "78px",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
    },
  },
  description: {
    fontSize: "18px",
    marginBottom: "25px",
    lineHeight: "1.3  !important",

    [theme.breakpoints.down("md")]: {
      marginBottom: "40px",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      marginBottom: "20px",
    },
  },
}));

export interface AuthorData {
  image: string;
  name: string;
  releaseDate: string;
  readTime: string;
}

interface BlogSegmentProps {
  thumbnail: string;
  title: string;
  link: string;
  description: string;
  author: AuthorData;
}

export const BlogSegment: React.FC<BlogSegmentProps> = ({
  thumbnail,
  title,
  link,
  description,
  author,
}) => {
  const { classes } = useStyles();

  return (
    <a className={classes.root} href={link} target="_blank" rel="noreferrer">
      <div className={classes.upperPart}>
        <img
          className={classes.image}
          src={`../images/${thumbnail}`}
          alt="thumbnail"
        />
        <MBlogSegmentFooter authorData={author} />
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </div>
      <BlogSegmentFooter authorData={author} />
    </a>
  );
};
