import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { AppBar } from "@mui/material";
import { HideOnScroll } from "../hide-on-scroll/HideOnScroll";
import { HeaderToolbar } from "./header-toolbar/HeaderToolbar";

const useStyles = makeStyles()((theme) => ({
  headerTransparent: {
    background: "none",
    color: "#FFF",
    boxShadow: "none",
  },
  header: {
    background: "#FFF",
    color: "#005AAA",
  },
}));

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const { classes } = useStyles();

  const [transparent, setTransparent] = useState(true);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setTransparent(window.pageYOffset + 100 < window.innerHeight)
      );
    }
  }, []);

  return (
    <HideOnScroll>
      <AppBar
        className={transparent ? classes.headerTransparent : classes.header}
      >
        <HeaderToolbar transparent={transparent} />
      </AppBar>
    </HideOnScroll>
  );
};
