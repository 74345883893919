import React, { ReactNode } from "react";
import ReactDOM from "react-dom/client";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { lrTheme } from "./core/theme";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

interface ProvidersProps {
  children: ReactNode;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return (
    <React.StrictMode>
      <StyledEngineProvider>
        <ThemeProvider theme={lrTheme}>
          <GoogleReCaptchaProvider
            reCaptchaKey={`${process.env.REACT_APP_SITE_KEY}`}
          >
            {children}
          </GoogleReCaptchaProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
};

root.render(
  <Providers>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Providers>
);
