import React from "react";
import { makeStyles } from "tss-react/mui";
import { FooterItem } from "./footer-item/FooterItem";
import { FooterList } from "./footer-list/FooterList";

const useStyles = makeStyles()((theme) => ({
  listSegment: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-evenly",
    width: "100%",
  },
}));

interface FooterNavigationProps {
  small?: boolean;
}

export const FooterNavigation: React.FC<FooterNavigationProps> = ({
  small = false,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.listSegment}>
      <FooterList small={small} title="Services" link="/#services">
        <FooterItem
          small={small}
          title="NMS"
          link="https://nodemonitoring.io"
          target="_blank"
        ></FooterItem>
        <FooterItem
          small={small}
          title="Consulting"
          link="/web3-consultancy"
        ></FooterItem>
        {/* <FooterItem
          small={small}
          title="NodeLogs"
          link="https://nodelogs.link/"
          target="_blank"
        ></FooterItem> */}
      </FooterList>
      <FooterList small={small} title="Resources" link="/resources">
        <FooterItem
          small={small}
          title="Blog"
          link="https://blog.linkriver.io/"
          target="_blank"
        ></FooterItem>
        <FooterItem
          small={small}
          title="Featured"
          link="/resources/#featured"
        ></FooterItem>
        {/* <FooterItem
          small={small}
          title="GitHub"
          link="/resources/#github"
        ></FooterItem> */}
        {process.env.REACT_APP_NODE_OPERATOR_MASTER_CLASS === "true" && (
          <FooterItem
            small={small}
            title="NOP Masterclass"
            link="/resources/#node-operator-masterclass"
          ></FooterItem>
        )}
        {/* <FooterItem
          small={small}
          title="Research"
          link="/resources/#research"
        ></FooterItem> */}
      </FooterList>
      <FooterList small={small} title="About" link="/about">
        <FooterItem
          small={small}
          title="History"
          link="/about/#history"
        ></FooterItem>
        <FooterItem
          small={small}
          title="Contact"
          link="/about/#contact"
        ></FooterItem>
      </FooterList>
      <FooterList small={small} title="Policies" link="/privacy-policy">
        <FooterItem
          small={small}
          title="Privacy"
          link="/privacy-policy"
        ></FooterItem>
        <FooterItem
          small={small}
          title="Cookies"
          link="/cookie-policy"
        ></FooterItem>
        <FooterItem
          small={small}
          title="Legal"
          link="/legal-disclaimer"
        ></FooterItem>
      </FooterList>
    </div>
  );
};
