import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    margin: "0 15%",

    [theme.breakpoints.down("sm")]: {
      margin: "0 7%",
    },
  },
  benefitsBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "85%",
    border: "1px solid #ADB3B9",
    borderRadius: "60px",
  },
  mBenefitsBox: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ADB3B9",
    borderRadius: "100px",
    width: "100%",
  },
  text: {
    margin: "15px 60px 15px 30px",
    color: "#012A4F",
    minHeight: "57px",
    width: "100%",
    fontSize: "24px",

    [theme.breakpoints.down("xl")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "19px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  image: {
    marginLeft: "30px",
    height: "100%",

    [theme.breakpoints.down("md")]: {
      height: "unset",
      width: "7%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "10%",
    },
  },
}));

interface BenefitsSegmentProps {
  segments: any[];
  left?: boolean;
  isMobile?: boolean;
}

export const BenefitsSegment: React.FC<BenefitsSegmentProps> = ({
  segments,
  left,
  isMobile,
}) => {
  const { classes } = useStyles();

  return (
    <Box sx={{ alignItems: left ? "start" : "end" }} className={classes.root}>
      {segments.map((text, index) => {
        return (
          <Box
            key={index + text}
            sx={{
              background: index % 2 !== 0 ? "#F6F9FD" : "white",
            }}
            className={isMobile ? classes.mBenefitsBox : classes.benefitsBox}
          >
            <img
              src="/images/benefits-ok.png"
              alt="benefits"
              className={classes.image}
            />
            <Typography className={classes.text}>{text}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
