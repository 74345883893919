import React from "react";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";

interface FooterToTopProps {}

export const FooterToTop: React.FC<FooterToTopProps> = () => {
  return (
    <ArrowCircleUpOutlinedIcon
      sx={{
        height: "40px",
        width: "40px",
        display: { xs: "flex", sm: "none" },
      }}
      onClick={() => window.scrollTo(0, 0)}
    />
  );
};
