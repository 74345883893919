import React, { useState, useCallback } from "react";
import { Button, TextField, Typography, Snackbar, Alert } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import * as yup from "yup";
import axios from "axios";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const useStyles = makeStyles()((theme) => ({
  contactSegment: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "500px",
    padding: "0 5vw",

    [theme.breakpoints.down("lg")]: {
      paddingTop: "0",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 10vw",
    },
    [theme.breakpoints.up("xxxl")]: {
      width: "26vw",
    },
  },
  segmentText: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "5vh",

    [theme.breakpoints.down("lg")]: {
      marginBottom: "0",
      paddingBottom: "5vh",
    },
    [theme.breakpoints.up("xxxl")]: {
      fontSize: "40px",
    },
  },
  textField: {
    marginBottom: "5vh",
    width: "100%",
    [theme.breakpoints.up("xxxl")]: {
      fontSize: "30px",
    },
  },
  formButton: {
    width: "220px",
    fontSize: "24px",
    textTransform: "none",
    backgroundColor: theme.palette.background2,
    color: theme.palette.primary.contrastText,
    borderRadius: "42px",
    paddingBottom: "8px",
    float: "right",

    "&:hover": {
      backgroundColor: "#000",
    },

    [theme.breakpoints.down("lg")]: {
      width: "120px",
      height: "30px",
      paddingBottom: "8px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "120px",
      height: "30px",
      paddingBottom: "8px",
      ...theme.typography.body5,
    },
    [theme.breakpoints.up("xxxl")]: {
      fontSize: "48px",
      width: "440px",
    },
  },
  disabledButton: {
    backgroundColor: "#BBB !important",
    color: "#FFF !important",
  },
  disclaimer: {
    marginBottom: "10px",
    [theme.breakpoints.up("xxxl")]: {
      fontSize: "30px",
    },
  },
  helperText: {
    fontSize: "20px",
  },
}));

interface ContactFormSegmentProps {}

interface FormProps {
  name: string;
  email: string;
  subject: string;
  message: string;
  recaptchaToken: string;
}

interface ErrorProps {
  nameError: {
    error: boolean;
    text: string;
  };
  emailError: {
    error: boolean;
    text: string;
  };
  subjectError: {
    error: boolean;
    text: string;
  };
  messageError: {
    error: boolean;
    text: string;
  };
}

export const ContactFormSegment: React.FC<ContactFormSegmentProps> = () => {
  const { classes } = useStyles();

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openNegative, setOpenNegative] = React.useState(false);
  const [token, setToken] = useState<string>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const [formValues, setFormValues] = useState<FormProps>({
    name: "",
    email: "",
    subject: "",
    message: "",
    recaptchaToken: "",
  });

  const [isValid, setIsValid] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });

  const [valueErrors, setValueErrors] = useState<ErrorProps>({
    nameError: {
      error: false,
      text: "",
    },
    emailError: {
      error: false,
      text: "",
    },
    subjectError: {
      error: false,
      text: "",
    },
    messageError: {
      error: false,
      text: "",
    },
  });

  const nameValidation = yup.object().shape({
    name: yup
      .string()
      .required("You must enter a name.")
      .max(30, "You con only enter 30 characters."),
  });
  const emailValidation = yup.object().shape({
    email: yup
      .string()
      .required("You must enter a e-mail.")
      .email("You must enter a valid email."),
  });
  const subjectValidation = yup.object().shape({
    subject: yup
      .string()
      .required("You must enter a subject.")
      .max(50, "You con only enter 50 characters."),
  });
  const messageValidation = yup.object().shape({
    message: yup
      .string()
      .required("You must enter a messagee.")
      .max(1500, "You con only enter 1500 characters."),
  });

  const handleChange = async (e: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        formValues.name = value;
        setIsValid({
          ...isValid,
          name: true,
        });
        try {
          await nameValidation.validate(formValues);
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            setValueErrors({
              ...valueErrors,
              nameError: { error: true, text: error.errors[0] },
            });
            setIsValid({
              ...isValid,
              name: false,
            });
          }
          break;
        }
        setValueErrors({
          ...valueErrors,
          nameError: {
            error: false,
            text: "",
          },
        });
        break;
      case "email":
        formValues.email = value;
        setIsValid({
          ...isValid,
          email: true,
        });
        try {
          await emailValidation.validate(formValues);
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            setValueErrors({
              ...valueErrors,
              emailError: { error: true, text: error.errors[0] },
            });
            setIsValid({
              ...isValid,
              email: false,
            });
          }
          break;
        }
        setValueErrors({
          ...valueErrors,
          emailError: {
            error: false,
            text: "",
          },
        });
        break;
      case "subject":
        formValues.subject = value;
        setIsValid({
          ...isValid,
          subject: true,
        });
        try {
          await subjectValidation.validate(formValues);
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            setValueErrors({
              ...valueErrors,
              subjectError: { error: true, text: error.errors[0] },
            });
            setIsValid({
              ...isValid,
              subject: false,
            });
          }
          break;
        }
        setValueErrors({
          ...valueErrors,
          subjectError: {
            error: false,
            text: "",
          },
        });
        break;
      case "message":
        formValues.message = value;
        setIsValid({
          ...isValid,
          message: true,
        });
        try {
          await messageValidation.validate(formValues);
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            setValueErrors({
              ...valueErrors,
              messageError: { error: true, text: error.errors[0] },
            });
            setIsValid({
              ...isValid,
              message: false,
            });
          }
          break;
        }
        setValueErrors({
          ...valueErrors,
          messageError: {
            error: false,
            text: "",
          },
        });
        break;
    }
  };

  const handleSubmit = async (event: any) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}api/Contactus`, {
        email: formValues.email,
        name: formValues.name,
        subject: formValues.subject,
        message: formValues.message,
        recaptchaToken: token,
      })
      .then(() => {
        setOpenSuccess(true);
        setFormValues({
          name: "",
          email: "",
          subject: "",
          message: "",
          recaptchaToken: "",
        });
        setIsValid({
          name: false,
          email: false,
          subject: false,
          message: false,
        });
      })
      .catch(() => {
        setOpenNegative(true);
      });
    setRefreshReCaptcha((r) => !r);
  };

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  return (
    <div className={classes.contactSegment}>
      <Snackbar
        open={openSuccess}
        autoHideDuration={5000}
        onClose={() => setOpenSuccess(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Thank you, we have received your message!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openNegative}
        autoHideDuration={5000}
        onClose={() => setOpenNegative(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Unfortunately, that didn't work, please try again later or send us an
          email directly!
        </Alert>
      </Snackbar>
      <Typography
        className={classes.segmentText}
        sx={{ typography: { sm: "body2", xs: "body5" } }}
      >
        Use the contact form below or write us at: contact@linkriver.io
      </Typography>
      <form noValidate>
        <TextField
          className={classes.textField}
          onChange={handleChange}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          id="name"
          label="Name"
          name="name"
          value={formValues.name}
          variant="outlined"
          required
          error={valueErrors.nameError.error}
          helperText={valueErrors.nameError.error && valueErrors.nameError.text}
        />
        <TextField
          className={classes.textField}
          onChange={handleChange}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          id="email"
          label="E-mail"
          name="email"
          value={formValues.email}
          variant="outlined"
          required
          error={valueErrors.emailError.error}
          helperText={
            valueErrors.emailError.error && valueErrors.emailError.text
          }
        />
        <TextField
          className={classes.textField}
          onChange={handleChange}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          id="subject"
          label="Subject"
          name="subject"
          value={formValues.subject}
          variant="outlined"
          required
          error={valueErrors.subjectError.error}
          helperText={
            valueErrors.subjectError.error && valueErrors.subjectError.text
          }
        />
        <TextField
          className={classes.textField}
          onChange={handleChange}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          id="message"
          label="Message"
          name="message"
          value={formValues.message}
          variant="outlined"
          multiline
          rows={4}
          required
          error={valueErrors.messageError.error}
          helperText={
            valueErrors.messageError.error && valueErrors.messageError.text
          }
        />
        <Typography
          className={classes.disclaimer}
          sx={{ typography: { sm: "body4", xs: "body7" } }}
        >
          By submitting the contact form, you accept LinkRiver's{" "}
          <a href="/privacy-policy">Privacy Policy</a>
        </Typography>
        <Typography
          className={classes.disclaimer}
          sx={{ typography: { sm: "body4", xs: "body7" } }}
        >
          This site is protected by reCAPTCHA and the Google{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{" "}
          apply.
        </Typography>
        <GoogleReCaptcha
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />
        <Button
          onClick={handleSubmit}
          className={classes.formButton}
          classes={{ disabled: classes.disabledButton }}
          disabled={
            !isValid.name ||
            !isValid.email ||
            !isValid.subject ||
            !isValid.message
          }
        >
          Contact us
        </Button>
      </form>
    </div>
  );
};
