import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  logoBox: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "250px",
    },
  },
  image: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "20px",
      height: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "15px",
      paddingBottom: "20px",
      height: "18px",
    },
  },
}));

interface FooterMediaProps {
  small?: boolean;
}

export const FooterMedia: React.FC<FooterMediaProps> = ({ small }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.logoBox}>
      <a
        href="https://twitter.com/linkriver_io"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className={classes.image}
          src="../images/twitter.png"
          alt={"service"}
        />
      </a>
      <a
        href="https://www.linkedin.com/company/linkriver/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className={classes.image}
          src="../images/linkedin.png"
          alt={"service"}
        />
      </a>
    </div>
  );
};
