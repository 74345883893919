import React from "react";
import { makeStyles } from "tss-react/mui";
import { Typography, Box } from "@mui/material";
import { AuthorData } from "../BlogSegment";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  authorImage: {
    height: "19px",
    borderRadius: "50px",
    marginRight: "15px",

    [theme.breakpoints.up("sm")]: {
      height: "28px",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "10px",
    },
  },
  name: {
    display: "flex",
    alignItems: "center",
    fontSize: "11px",
    fontWeight: 500,

    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
    },
  },
  date: {
    fontSize: "11px",
    fontWeight: 500,

    [theme.breakpoints.up("sm")]: {
      fontSize: "15px",
    },
  },
  left: {
    display: "flex",
  },
}));

interface MBlogSegmentFooterProps {
  authorData: AuthorData;
}

export const MBlogSegmentFooter: React.FC<MBlogSegmentFooterProps> = ({
  authorData,
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.left}>
        <img
          className={classes.authorImage}
          src={`../images/${authorData.image}.png`}
          alt="author"
        ></img>
        <Typography className={classes.name}>{authorData.name}</Typography>
      </Box>
      <Typography
        className={classes.date}
      >{`${authorData.releaseDate}\u00A0\u00A0•\u00A0\u00A0${authorData.readTime}`}</Typography>
    </Box>
  );
};
