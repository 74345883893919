import React from "react";
import { makeStyles } from "tss-react/mui";
import { Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles()((theme) => ({
  closeButton: {
    height: "86.5px",
    paddingRight: "16px",
    textTransform: "none",
    justifyContent: "end",
  },
}));

interface CloseButtonProps {
  onClick: () => void;
}

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  const { classes } = useStyles();

  return (
    <Button
      onClick={onClick}
      className={classes.closeButton}
      endIcon={<CloseIcon />}
    >
      <Typography variant="button3">Close</Typography>
    </Button>
  );
};
