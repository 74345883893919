import { createTheme } from "@mui/material/styles";
import {
  PaletteOptions,
  SimplePaletteColorOptions,
} from "@mui/material/styles/createPalette";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import type { ThemeOptions } from "@mui/material/";

const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightSemiBold = 600;
const fontWeightBold = 700;

const breakpoints = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1090,
      lg: 1200,
      xl: 1536,
      xxl: 2200,
      xxxl: 3400,
    },
  },
});

const palette: PaletteOptions = {
  primary: {
    light: "#555",
    main: "#000",
    contrastText: "#fff",
  },
  footerItem: "#ADB3B9",
  background2: "#012A4F",
  background3: "rgba(69, 190, 152, 0.05)",
  gradient1: {
    0: "rgba(256, 256, 256, 0)",
    1: "rgba(0, 90, 170, 100)",
    2: "rgba(65, 180, 150, 80)",
    3: "rgba(50, 120, 190, 80)",
    10: "rgba(0, 90, 170, 1)",
    20: "rgba(65, 180, 150, 1)",
    30: "rgba(50, 120, 190, 1)",
  },
  gradient2: {
    0: "rgba(256, 256, 256, 0)",
    1: "rgba(0, 5, 170, 100)",
    2: "rgba(65, 180, 150, 80)",
    3: "rgba(0, 180, 59, 80)",
    10: "rgba(0, 90, 170, 1)",
    20: "rgba(65, 180, 150, 1)",
    30: "rgba(50, 120, 190, 1)",
  },
  gradient3: {
    0: "rgba(256, 256, 256, 0)",
    1: "rgba(50, 120, 190, 100)",
    2: "rgba(65, 180, 150, 80)",
    3: "rgba(216, 229, 245, 80)",
    10: "rgba(50, 120, 190, 1)",
    20: "rgba(65, 180, 150, 1)",
    30: "rgba(216, 229, 245, 1)",
  },
  gradient4: {
    0: "rgba(256, 256, 256, 0)",
    1: "rgba(50, 120, 190, 100)",
    2: "rgba(0, 0, 0, 80)",
    3: "rgba(10, 37, 130, 80)",
    10: "rgba(50, 120, 190, 1)",
    20: "rgba(0, 0, 0, 1)",
    30: "rgba(10, 37, 130, 1)",
  },
};

const typography: TypographyOptions = {
  fontFamily: '"Darker Grotesque", Roboto, Arial, sans-serif',
  h1: {
    fontSize: "45px",
    fontWeight: fontWeightSemiBold,
  },
  h2: {
    fontSize: "41px",
    fontWeight: fontWeightSemiBold,
  },
  h3: {
    fontSize: "36px",
    fontWeight: fontWeightBold,
  },
  h4: {
    fontSize: "30px",
    fontWeight: fontWeightBold,
  },
  h5: {
    fontSize: "23px",
    fontWeight: fontWeightBold,
  },
  h6: {
    fontSize: "30px",
    fontWeight: fontWeightBold,
  },
  h7: {
    fontSize: "36px",
    fontWeight: fontWeightMedium,
  },
  h8: {
    fontSize: "27px",
    fontWeight: fontWeightSemiBold,
  },
  h9: {
    fontSize: "48px",
    fontWeight: fontWeightMedium,
  },
  subtitle: {
    fontSize: "30px",
    fontWeight: fontWeightMedium,
  },
  subtitle1: {
    fontSize: "25px",
    fontWeight: fontWeightMedium,
  },
  subtitle2: {
    fontSize: "23px",
    fontWeight: fontWeightMedium,
  },
  subtitle3: {
    fontSize: "20px",
    fontWeight: fontWeightRegular,
  },
  subtitle4: {
    fontSize: "18px",
    fontWeight: fontWeightMedium,
  },
  subtitle5: {
    fontSize: "18px",
    fontWeight: fontWeightSemiBold,
  },
  body1: {
    fontSize: "20px",
    fontWeight: fontWeightMedium,
  },
  body2: {
    fontSize: "18px",
    fontWeight: fontWeightMedium,
  },
  body3: {
    fontSize: "14px",
    fontWeight: fontWeightRegular,
  },
  body4: {
    fontSize: "15px",
    fontWeight: fontWeightRegular,
  },
  body5: {
    fontSize: "15px",
    fontWeight: fontWeightMedium,
  },
  body6: {
    fontSize: "12px",
    fontWeight: fontWeightMedium,
  },
  body7: {
    fontSize: "11px",
    fontWeight: fontWeightMedium,
  },
  button1: {
    fontSize: "20px",
    fontWeight: fontWeightMedium,
  },
  button2: {
    fontSize: "14px",
    fontWeight: fontWeightMedium,
  },
  button3: {
    fontSize: "15px",
    fontWeight: fontWeightSemiBold,
  },
  statNumber1: {
    fontSize: "72px",
    fontWeight: fontWeightSemiBold,
  },
  statNumber2: {
    fontSize: "48px",
    fontWeight: fontWeightSemiBold,
  },
};

const themeConfig: ThemeOptions = {
  palette,
  typography,
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: typography.body2?.fontSize,
          fontWeight: typography.body2?.fontWeight,
          color: (palette.primary as SimplePaletteColorOptions).contrastText,
          justifyContent: "space-around",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          width: "60%",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          [breakpoints.breakpoints.down("sm")]: {
            ...typography.body5,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "14px",
          [breakpoints.breakpoints.down("sm")]: {
            ...typography.body5,
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1090,
      lg: 1200,
      xl: 1536,
      xxl: 2200,
      xxxl: 3400,
    },
  },
};

interface Gradient {
  0: string;
  1: string;
  2: string;
  3: string;
  10: string;
  20: string;
  30: string;
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h7: React.CSSProperties;
    h8: React.CSSProperties;
    h9: React.CSSProperties;
    subtitle: React.CSSProperties;
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    subtitle5: React.CSSProperties;
    body1: React.CSSProperties;
    body2: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    body6: React.CSSProperties;
    body7: React.CSSProperties;
    button1: React.CSSProperties;
    button2: React.CSSProperties;
    button3: React.CSSProperties;
    statNumber1: React.CSSProperties;
    statNumber2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    h8?: React.CSSProperties;
    h9?: React.CSSProperties;
    subtitle?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
    subtitle4?: React.CSSProperties;
    subtitle5?: React.CSSProperties;
    body1?: React.CSSProperties;
    body2?: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5: React.CSSProperties;
    body6: React.CSSProperties;
    body7: React.CSSProperties;
    button1?: React.CSSProperties;
    button2?: React.CSSProperties;
    button3?: React.CSSProperties;
    statNumber1?: React.CSSProperties;
    statNumber2?: React.CSSProperties;
  }

  interface BreakpointOverrides {
    xxl: true;
    xxxl: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
    h9: true;
    subtitle: true;
    subtitle3: true;
    subtitle4: true;
    subtitle5: true;
    body1: true;
    body2: true;
    body3: true;
    body4: true;
    body5: true;
    body6: true;
    body7: true;
    button1: true;
    button2: true;
    button3: true;
    statNumber1: true;
    statNumber2: true;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions {
    footerItem: string;
    background2: string;
    background3: string;
    gradient1: Gradient;
    gradient2: Gradient;
    gradient3: Gradient;
    gradient4: Gradient;
  }

  interface Palette {
    footerItem: string;
    background2: string;
    background3: string;
    gradient1: Gradient;
    gradient2: Gradient;
    gradient3: Gradient;
    gradient4: Gradient;
  }
}

export const lrTheme = createTheme(themeConfig);
