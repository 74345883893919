import React from "react";
import { makeStyles } from "tss-react/mui";
import { FooterLogo } from "./footer-logo/FooterLogo";
import { FooterNavigation } from "./footer-navigation/FooterNavigation";
import { FooterToTop } from "./footer-to-top/FooterToTop";
import { FooterCopyright } from "./footer-copyright/FooterCopyright";
import { FooterMedia } from "./footer-media/FooterMedia";

const useStyles = makeStyles()((theme) => ({
  background: {
    background: theme.palette.background2,
    paddingBottom: "5px",
  },
  footer: {
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up("sm")]: {
      padding: "80px 80px 30px 80px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 30px 0 30px",
    },
  },
  topBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "80px 80px 40px 80px",
    },
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      flexDirection: "row",
    },
  },
  middleBox: {
    display: "flex",
    width: "100%",
    minHeight: "50px",
  },
  bottomBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  disabledOnSmall: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  disabledOnBig: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  filler: {
    width: "250px",
  },
}));

interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.background}>
      <div className={classes.footer}>
        <div className={classes.topBox}>
          <FooterLogo />
          <FooterToTop />
          <div className={classes.disabledOnSmall}>
            <FooterNavigation />
          </div>
        </div>
        <div className={classes.middleBox}>
          <div className={classes.disabledOnBig}>
            <FooterMedia small />
            <FooterNavigation small />
          </div>
        </div>
        <div className={classes.bottomBox}>
          <div className={classes.filler} />
          <FooterCopyright />
          <FooterMedia />
        </div>
      </div>
      <div className={classes.disabledOnBig}>
        <FooterCopyright small />
      </div>
    </div>
  );
};
