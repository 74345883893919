import React from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { LandingSection } from "../../shared/landing-section/LandingSection";
import MetaTags from "react-meta-tags";

const useStyles = makeStyles()((theme) => ({
  privacyPolicy: {
    margin: "10vh 10vw",
  },
  title: { marginBottom: "2vh" },
  subTitle: { marginBottom: "4vh" },
  listItem: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    margin: "2vh",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body5.fontSize,
      fontWeight: theme.typography.body5.fontWeight,
    },
  },
}));

interface LegalDisclaimerPageProps {}

export const LegalDisclaimerPage: React.FC<LegalDisclaimerPageProps> = () => {
  const { classes } = useStyles();
  const { palette } = useTheme();

  return (
    <>
      <MetaTags>
        <meta name="robots" content="noindex" />
      </MetaTags>
      <LandingSection
        title="Legal Disclaimer"
        subTitle=""
        gradient={palette.gradient4}
      />
      <div className={classes.privacyPolicy}>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Legal disclaimer
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          These publicly shared resources (Content) were created by LinkRiver
          for educational/informative purposes only. For the purposes of this
          disclaimer, “Content” shall mean the resources provided through the
          LinkRiver website, blog and any other document mentioned therein as
          well as any recording/video training made available in relation with
          the Content through any web-based source.
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          LinkRiver makes no representations or warranties about the accuracy or
          suitability of any information of Content which is provided on an “as
          is” basis. The information contained in Content is not intended to
          constitute legal, financial, tax, investment or any other type of
          advice or the rendering of professional services of any kind. The
          merits or suitability of Content to any particular situation should be
          independently determined by any interested party. Users of information
          contained in Content should not in any manner rely upon or construe
          the information or resource materials referenced in Content as legal,
          financial, tax, investment or any other type of advice and should not
          act or fail to act based upon the information of Content without
          seeking, inter alia, an assessment of the relevant legal, tax,
          accounting, regulatory, financial, and other related aspects.
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          LinkRiver is not liable for any claims, losses, or damages of any kind
          arising out of or in any way related to the information provided in
          Content and hereby disclaims all liability for any claims, losses, or
          damages of any kind in connection with use or application of such
          information by any third party.
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          The contents of Content have not been independently verified by or on
          behalf of LinkRiver or any of its advisors (the “Advisors”), or by any
          other independent third party. No representation, warranty or
          undertaking, express or implied, is made by any of LinkRiver, the
          Advisors, any of their respective affiliates or any of their or their
          affiliates’ respective members, directors, officers or employees as
          to, and no reliance should be placed on and no right of action should
          arise in relation with, the fairness, accuracy, completes, timeliness,
          correctness, including without limitation all warranties of title,
          non-infringement, merchantability, and fitness for a particular
          purpose of the information or the opinions contained herein. None of
          LinkRiver or the Advisors nor any of their respective members,
          directors, officers, employees, affiliates, advisers or
          representatives shall have any liability whatsoever (in negligence or
          otherwise) for any loss whosoever arising from any use of this Content
          or otherwise arising in connection with the Content. The information
          presented herein is provided as at the date of this Content and will
          be deemed to be superseded by any subsequent versions of this Content.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Copyright
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          All copyright and intellectual property rights in relations with this
          Content belong to LinkRiver and any act of its copying, reproduction
          and distribution in any jurisdiction without explicit written consent
          of LinkRiver shall entail immediate legal action.
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          © All rights reserved
        </Typography>
      </div>
    </>
  );
};
