import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { SubPageSelection } from "./sub-page-selection/SubPageSelection";
import MetaTags from "react-meta-tags";

interface GradientProps {
  0: string;
  1: string;
  2: string;
  3: string;
  10: string;
  20: string;
  30: string;
}

const useStyles = makeStyles<{ gradient: GradientProps }>()(
  (theme, { gradient }) => ({
    backgroundAnimation: {
      overflow: "hidden",
      background: `linear-gradient(to right, #000, ${theme.palette.gradient2[1]}, ${theme.palette.gradient2[2]}, ${theme.palette.gradient2[3]}, ${theme.palette.gradient2[1]}, #000) no-repeat, 
            radial-gradient(ellipse at top right, #41b496 0%,#0a77d5 34%,#73b1e7 69%,${theme.palette.gradient2[1]} 69%,#539fe1 89%,#539fe1 89%,#87bcea 97%),
            radial-gradient(ellipse at bottom, #4c4c4c80 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%)`,
      animation: "background-animation 11s ease infinite",
      backgroundSize: "200% 100%",
      "@keyframes background-animation": {
        "0%": {
          backgroundPosition: "0% 100%",
        },
        "50%": {
          backgroundPosition: "100% 50%",
        },
        "100%": {
          backgroundPosition: "0% 100%",
        },
      },
    },
    gradient: {
      background: `linear-gradient(to left top, ${theme.palette.gradient2[30]}, ${theme.palette.gradient2[20]}, ${theme.palette.gradient2[0]} 50%, ${theme.palette.gradient2[20]}, ${theme.palette.gradient2[10]}, ${gradient[30]}, ${gradient[1]}), 
            linear-gradient(to bottom, ${theme.palette.gradient2[20]}, ${theme.palette.gradient2[0]} 50%, ${theme.palette.gradient2[1]}, ${theme.palette.gradient2[10]}, ${theme.palette.gradient2[30]}),
            linear-gradient(to top, ${theme.palette.gradient2[20]}, ${theme.palette.gradient2[0]} 50%, ${theme.palette.gradient2[1]}, ${theme.palette.gradient2[10]}, ${theme.palette.gradient2[30]})`,
      animation: "gradient-animation 15s ease infinite",
      backgroundSize: "150% 150%",
      "@keyframes gradient-animation": {
        "0%": {
          backgroundPosition: "0% 100%",
        },
        "50%": {
          backgroundPosition: "100% 50%",
        },
        "100%": {
          backgroundPosition: "0% 100%",
        },
      },
      display: "flow-root",
    },
    headline: {
      ...theme.typography.statNumber2,
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      color: theme.palette.common.white,
      margin: 0,
      marginTop: "240px",

      [theme.breakpoints.down("md")]: {
        fontSize: "40px",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "26px",
      },
      [theme.breakpoints.up("xxxl")]: {
        fontSize: "90px",
        marginTop: "22.2vh",
      },
    },
    subtitle: {
      display: "flex",
      color: theme.palette.common.white,
      justifyContent: "center",
      marginBottom: "160px",

      [theme.breakpoints.down("md")]: {
        fontSize: "21px",
      },

      [theme.breakpoints.down("sm")]: { fontSize: "18px" },
      [theme.breakpoints.up("xxxl")]: {
        fontSize: "45px",
      },
    },
  })
);

interface ConsultingPageProps {}

export const ConsultingPage: React.FC<ConsultingPageProps> = () => {
  const { palette } = useTheme();
  const gradient = palette.gradient2;
  const { classes } = useStyles({ gradient });

  return (
    <>
      <MetaTags>
        <title>
          Consulting: Scale your blockchain validations with Chainlink
        </title>
        <meta
          name="description"
          content="Succeed in the wild waters of decentralized technologies by gaining knowledge from industry experts providing unique Web3 consultancy services."
        />
        <meta name="robots" content="index, follow" />
        <meta name="robots" content="max-image-preview:large" />
        <link rel="canonical" href="https://linkriver.io/web3-consultancy" />
      </MetaTags>
      <Box className={classes.backgroundAnimation}>
        <Box className={classes.gradient}>
          <h1 className={classes.headline}>
            Industry-Leading Web3 Consultancy Service
          </h1>
          <Typography variant="subtitle2" className={classes.subtitle}>
            Explore how to succeed in the wild waters of Web3
          </Typography>
          <SubPageSelection />
        </Box>
      </Box>
    </>
  );
};
