import React from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import { HistorySegment } from "./segments/HistorySegment";
import { AnimateComponentOnScroll } from "../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  historySection: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 3vw 0",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      margin: "5vh 0",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    margin: "10vh auto",
    marginBottom: "5vh",

    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
    [theme.breakpoints.up("xxxl")]: {
      fontSize: "90px",
    },
  },
  launching: {
    display: "flex",
    width: "100%",
    margin: "0 100px 100px",
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      margin: "0 10px 20px",
    },
  },
  scaling: {
    display: "flex",
    width: "100%",
    margin: "0 100px 0",

    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      margin: "0 10px 20px",
    },
  },
  contributing: {
    display: "flex",
    width: "100%",
    margin: "0 100px 100px",
    flexWrap: "wrap",

    [theme.breakpoints.down("sm")]: {
      margin: "0 10px 20px",
    },
  },
  launchingText: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "space-around",
    margin: "0 0 0 80px",

    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },

    [theme.breakpoints.down("sm")]: {
      margin: "0 25px",
    },
  },
  launchingImage: {
    height: "370px",

    [theme.breakpoints.down("lg")]: {
      margin: "auto",
      width: "250px",
      height: "250px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "250px",
      height: "250px",
    },
  },
  contributingText: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "space-around",
    margin: "0 40px",
    height: "800px",

    [theme.breakpoints.down("lg")]: {
      margin: "0",
      alignContent: "start",
      gap: "25px",
      height: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      height: "fit-content",
      gap: "0",
    },
  },
  contributingImages: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",

    [theme.breakpoints.down("xl")]: {
      "& > img": {
        width: "400px",
      },
    },
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  contributingImagesBox: {
    display: "flex",
    flexWrap: "wrap",

    [theme.breakpoints.down("lg")]: {
      width: "100%",
      justifyContent: "space-around",
    },
  },
  contributingImagesSmall: {
    display: "flex",
    width: "270px",
    margin: "0 auto",

    [theme.breakpoints.down("lg")]: {
      width: "50%",
      marginBottom: "25px",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
      marginBottom: "25px",
    },
  },
  contributingFirstParagraph: {
    width: "800px",
    marginBottom: "100px",

    [theme.breakpoints.down("lg")]: {
      width: "100%",
      marginBottom: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0",
      padding: "25px",
    },
  },
  contributingSecondParagraph: {
    display: "flex",
    flexWrap: "nowrap",

    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  paragraph: {
    padding: "0 25px 25px",

    [theme.breakpoints.down("lg")]: {
      padding: "0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 25px 25px",
    },
  },
  scalingText: {
    textAlign: "center",
  },
  scalingIcon: {
    margin: "80px",
    width: "30%",

    [theme.breakpoints.down("lg")]: {
      width: "50%",
      margin: "30px",
    },
  },
}));

interface HistorySectionProps {}

export const HistorySection: React.FC<HistorySectionProps> = () => {
  const { classes } = useStyles();
  return (
    <div id="history" className={classes.historySection}>
      <Typography
        className={classes.title}
        sx={{ typography: { sm: "h3", xs: "subtitle1" } }}
      >
        Our History
      </Typography>

      <AnimateComponentOnScroll enterDirection="bottom" width="100%">
        <HistorySegment
          year="2020"
          title="Launching"
          yearColor="#3278BE"
          backgroundColor="#3278BE0D"
        >
          <div className={classes.launching}>
            <img
              className={classes.launchingImage}
              src="../images/history-launching.png"
              alt="launching"
            />
            <div className={classes.launchingText}>
              <Typography
                sx={{
                  paddingBottom: "30px",
                  paddingTop: { md: "0", xs: "30px" },
                  typography: {
                    sm: "subtitle1",
                    xs: "body5",
                    xxxl: "subtitle1",
                  },
                }}
              >
                As longtime members of the Chainlink community, the LinkRiver
                founders decided to launch their own node operating business
                while following the SmartCon 0 in 2020.
              </Typography>
              <Typography
                sx={{
                  typography: {
                    sm: "subtitle1",
                    xs: "body5",
                    xxxl: "subtitle1",
                  },
                }}
              >
                After prevailing in a selection process lasting several weeks
                against teams that combined decades of IT experience, LinkRiver
                was onboarded as a newly verified node operator in their first
                Chainlink decentralized oracle networks.
              </Typography>
            </div>
          </div>
        </HistorySegment>
      </AnimateComponentOnScroll>
      <AnimateComponentOnScroll enterDirection="bottom" width="100%">
        <HistorySegment
          year="2021"
          title="Contributing"
          yearColor="#45BE98"
          backgroundColor="#45BE980D"
        >
          <div className={classes.contributing}>
            <div className={classes.contributingImagesBox}>
              <img
                className={classes.contributingImagesSmall}
                src="../images/history-contributing-first.png"
                alt="contributing"
              />
              <img
                className={classes.contributingImagesSmall}
                src="../images/history-contributing-second.png"
                alt="contributing"
              />
            </div>
            <Typography
              className={classes.contributingFirstParagraph}
              sx={{
                typography: { sm: "subtitle1", xs: "body5", xxxl: "h7" },
              }}
            >
              Due to LinkRiver's dedication to the Chainlink ecosystem and their
              strong node performance, they were selected by Chainlink to help
              secure various products.
            </Typography>
            <div className={classes.contributingSecondParagraph}>
              <div className={classes.contributingImages}>
                <img
                  src="../images/history-contributing-first.png"
                  alt="contributing"
                />
                <img
                  src="../images/history-contributing-second.png"
                  alt="contributing"
                />
              </div>
              <div className={classes.contributingText}>
                <Typography
                  className={classes.paragraph}
                  sx={{
                    typography: {
                      sm: "subtitle1",
                      xs: "body5",
                      xxxl: "subtitle1",
                    },
                  }}
                >
                  At that time, these included{" "}
                  <a
                    href="https://chain.link/data-feeds"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Market and Data Feeds
                  </a>
                  ,{" "}
                  <a
                    href="https://chain.link/proof-of-reserve"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Proof of Reserve
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://chain.link/automation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Automation
                  </a>{" "}
                  across a variety of blockchain networks.
                </Typography>
                <Typography
                  className={classes.paragraph}
                  sx={{
                    typography: {
                      sm: "subtitle1",
                      xs: "body5",
                      xxxl: "subtitle1",
                    },
                  }}
                >
                  In September 2021, LinkRiver held their{" "}
                  <a
                    href="https://www.youtube.com/watch?v=jD9903qyt6w"
                    target="_blank"
                    rel="noreferrer"
                  >
                    own panel during SmartCon 1
                  </a>
                  , where they answered frequently asked questions from the node
                  operator community and provided advice on how to become a
                  high-performing Chainlink node operator.
                </Typography>
                <Typography
                  className={classes.paragraph}
                  sx={{
                    typography: {
                      sm: "subtitle1",
                      xs: "body5",
                      xxxl: "subtitle1",
                    },
                  }}
                >
                  Additionally,{" "}
                  <a
                    href="https://blog.chain.link/linkriver-receives-chainlink-grant-for-node-operator-debugging-website/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Chainlink Labs awarded LinkRiver a grant
                  </a>{" "}
                  to develop a debugging platform for Chainlink node operators.
                </Typography>
              </div>
            </div>
          </div>
        </HistorySegment>
      </AnimateComponentOnScroll>
      <AnimateComponentOnScroll enterDirection="bottom" width="100%">
        <HistorySegment
          year="2022"
          title="Scaling"
          yearColor="#5F5F5F"
          backgroundColor="#5F5F5F0D"
        >
          <div className={classes.scaling}>
            <div className={classes.scalingText}>
              <Typography
                className={classes.paragraph}
                sx={{
                  typography: {
                    sm: "subtitle1",
                    xs: "body5",
                    xxxl: "subtitle1",
                  },
                }}
              >
                By steadily expanding into a team of highly sophisticated,
                globally distributed experts, LinkRiver has established itself
                as a leading node operator and has therefore been selected to
                secure Chainlink's latest and most groundbreaking products.{" "}
                <a
                  href="https://chain.link/data-streams"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Streams
                </a>{" "}
                powers the next generation of highly innovative DeFi projects
                and{" "}
                <a
                  href="https://chain.link/functions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Functions
                </a>{" "}
                enables the onboarding of millions of Web2 developers into the
                world of the verifiable web. The most revolutionary Chainlink
                product, however, is the{" "}
                <a
                  href="https://chain.link/functions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cross-Chain Interoperability Protocol (CCIP)
                </a>
                . The LinkRiver team is proud to be one of the few node
                operators securing this new standard from the start.
              </Typography>
              <Typography
                className={classes.paragraph}
                sx={{
                  typography: {
                    sm: "subtitle1",
                    xs: "body5",
                    xxxl: "subtitle1",
                  },
                }}
              >
                Since LinkRiver is in close communication with their peers and
                deeply understands the challenges faced by Chainlink node
                operators, they have decided to ease one of the most frequently
                cited problems - setting up and maintaining a comprehensive,
                scalable infrastructure monitoring system. In October 2024, they
                conducted the public launch of the first{" "}
                <a
                  href="https://nodemonitoring.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Chainlink node monitoring service (NMS)
                </a>{" "}
                of its kind in their own panel at SmartCon 4 in Hong Kong.
              </Typography>
              <img
                className={classes.scalingIcon}
                src="../images/history-scaling.png"
                alt="scaling"
              />
            </div>
          </div>
        </HistorySegment>
      </AnimateComponentOnScroll>
    </div>
  );
};
