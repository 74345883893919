import React from "react";
import { useTheme } from "@mui/material/styles";
import { LandingSection } from "../../shared/landing-section/LandingSection";
import { HiringSection } from "../../shared/hiring-section/HiringSection";
import { BlogSection } from "./sections/blog-section/BlogSection";
import { FeaturedSection } from "./sections/featured-section/FeaturedSection";
import Newsletter from "../../shared/newsletter-subscribe/Newsletter";
import { AnimateComponentOnScroll } from "../../shared/animate-component-on-scroll/AnimateComponentOnScroll";
import MetaTags from "react-meta-tags";

interface ResourcesPageProps {}

export const ResourcesPage: React.FC<ResourcesPageProps> = () => {
  const { palette } = useTheme();

  return (
    <>
      <MetaTags>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://linkriver.io/resources" />
      </MetaTags>
      <LandingSection
        title="Resources"
        subTitle="How we do it"
        gradient={palette.gradient3}
      />

      <BlogSection />
      <FeaturedSection />
      {/* <GithubSection /> */}
      {/* <ResearchSection /> */}
      {process.env.REACT_APP_HIRING === "true" && <HiringSection />}
      <AnimateComponentOnScroll enterDirection="bottom">
        <Newsletter />
      </AnimateComponentOnScroll>
    </>
  );
};
