import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ImageSegment } from "./image-segment/ImageSegment";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "0 10vw 10vh",
  },
  title: {
    margin: "10vh 0 5vh",
    color: "#012A4F",
    fontSize: "40px",
    fontWeight: 600,

    [theme.breakpoints.down("md")]: {
      fontSize: "38px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
    },
  },
  description: {
    marginBottom: "10vh",
    color: "#012A4F",
    textAlign: "center",
    fontSize: "28px",

    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
      marginBottom: "0",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  segmentBox: {
    display: "flex",
    justifyContent: "center",
    gap: "100px",
    alignItems: "space-between",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "5vh",
    },
  },
}));

export interface SegmentProps {
  image: string;
  title: string;
  subTitle: string;
}

interface HowItWorksSectionProps {
  description: string;
  first: SegmentProps;
  second: SegmentProps;
  third: SegmentProps;
}

export const HowItWorksSection: React.FC<HowItWorksSectionProps> = ({
  description,
  first,
  second,
  third,
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <h3 className={classes.title}>How it works</h3>
      <Typography className={classes.description}>{description}</Typography>
      <Box className={classes.segmentBox}>
        <ImageSegment imageSegment={first}></ImageSegment>
        <ImageSegment imageSegment={second}></ImageSegment>
        <ImageSegment imageSegment={third}></ImageSegment>
      </Box>
    </Box>
  );
};
