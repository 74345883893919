import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  logoLink: {
    display: "flex",
    alignContent: "center",
  },
  logo: {
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "48px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "119px",
      height: "39px",
    },
    [theme.breakpoints.up("xxxl")]: {
      width: "300px",
      height: "96px",
    },
  },
}));

interface HeaderLogoProps {
  transparent: boolean;
}

export const HeaderLogo: React.FC<HeaderLogoProps> = ({ transparent }) => {
  const { classes } = useStyles();

  return (
    <a className={classes.logoLink} href="/">
      <img
        className={classes.logo}
        src={
          transparent ? "../images/lr-logo.png" : "../images/lr-logo-blue.png"
        }
        alt={"app logo"}
      />
    </a>
  );
};
