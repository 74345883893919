import React from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { LandingSection } from "../../shared/landing-section/LandingSection";
import { CookieSegment } from "./CookieSegment";
import MetaTags from "react-meta-tags";

const useStyles = makeStyles()((theme) => ({
  privacyPolicy: {
    margin: "10vh 10vw",
  },
  title: { marginBottom: "2vh" },
  titleUnderline: { marginBottom: "2vh", textDecoration: "underline" },
  subTitle: { marginBottom: "4vh" },
  link: {
    textDecoration: "none",
  },
  list: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    margin: "2vh",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body5.fontSize,
      fontWeight: theme.typography.body5.fontWeight,
    },
  },
}));

interface CookiePolicyPageProps {}

export const CookiePolicyPage: React.FC<CookiePolicyPageProps> = () => {
  const { classes } = useStyles();
  const { palette } = useTheme();

  return (
    <>
      <MetaTags>
        <meta name="robots" content="noindex" />
      </MetaTags>
      <LandingSection title="Our Cookie Policy" gradient={palette.gradient4} />
      <div className={classes.privacyPolicy}>
        <Typography
          component="div"
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Cookie Policy
          <Typography
            sx={{ typography: { sm: "subtitle4", xs: "body6" } }}
            className={classes.title}
          >
            Last updated July 13, 2023
          </Typography>
        </Typography>
        <Typography
          className={classes.subTitle}
          sx={{ typography: { sm: "body1", xs: "body5" } }}
        >
          This Cookie Policy explains how LinkRiver LLC ("
          <strong>Company</strong>", "<strong>we</strong>", "<strong>us</strong>
          " and "<strong>our</strong>") uses cookies and similar technologies to
          recognize you when you visit our website at{" "}
          <a href="https://linkriver.io" target="_blank" rel="noreferrer">
            https://linkriver.io
          </a>{" "}
          ("<strong>Website</strong>"). It explains what these technologies are
          and why we use them, as well as your rights to control our use of
          them.
          <br></br>
          <br></br>
          In some cases we may use cookies to collect personal information, or
          that becomes personal information if we combine it with other
          information.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          What are cookies?
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          Cookies are small data files that are placed on your computer or
          mobile device when you visit a website. Cookies are widely used by
          website owners in order to make their websites work, or to work more
          efficiently, as well as to provide reporting information.
          <br></br>
          <br></br>
          Cookies set by the website owner (in this case, LinkRiver LLC) are
          called "first-party cookies." Cookies set by parties other than the
          website owner are called "third-party cookies." Third-party cookies
          enable third-party features or functionality to be provided on or
          through the website (e.g., advertising, interactive content, and
          analytics). The parties that set these third-party cookies can
          recognize your computer both when it visits the website in question
          and also when it visits certain other websites.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Why do we use cookies?
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          We use first- and third-party cookies for several reasons. Some
          cookies are required for technical reasons in order for our Website to
          operate, and we refer to these as "essential" or "strictly necessary"
          cookies. Other cookies also enable us to track and target the
          interests of our users to enhance the experience on our Online
          Properties. Third parties serve cookies through our Website for
          advertising, analytics, and other purposes. This is described in more
          detail below.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          How can I control cookies?
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          You have the right to decide whether to accept or reject cookies. You
          can exercise your cookie rights by setting your preferences in the
          Cookie Consent Manager. The Cookie Consent Manager allows you to
          select which categories of cookies you accept or reject. Essential
          cookies cannot be rejected as they are strictly necessary to provide
          you with services.
          <br></br>
          <br></br>
          The Cookie Consent Manager can be found in the notification banner and
          on our website. If you choose to reject cookies, you may still use our
          website though your access to some functionality and areas of our
          website may be restricted. You may also set or amend your web browser
          controls to accept or refuse cookies.
          <br></br>
          <br></br>
          The specific types of first- and third-party cookies served through
          our Website and the purposes they perform are described in the table
          below (please note that the specific cookies served may vary depending
          on the specific Online Properties you visit):
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.titleUnderline}
        >
          Essential website cookies:
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          These cookies are strictly necessary to provide you with services
          available through our Website and to use some of its features, such as
          access to secure areas.
        </Typography>
        <CookieSegment
          name="rc::f"
          purpose="Used to track and analyze user behavior to distinguish humans from bots or automated software."
          provider="www.google.com"
          service="reCAPTCHA"
          serviceLink="https://policies.google.com/privacy"
          country="United States"
          type="html_local_storage"
          expires="persistent"
        ></CookieSegment>
        <CookieSegment
          name="_grecaptcha"
          purpose="Stores a value used to verify that the user is not a bot."
          provider="linkriver.io"
          service="reCAPTCHA"
          serviceLink="https://policies.google.com/privacy"
          country="France"
          type="html_local_storage"
          expires="persistent"
        ></CookieSegment>
        <CookieSegment
          name="_GRECAPTCHA"
          purpose="Stores a value used to verify that the user is not a bot."
          provider="www.google.com"
          service="reCAPTCHA"
          serviceLink="https://policies.google.com/privacy"
          country="United States"
          type="http_cookie"
          expires="5 months 27 days"
        ></CookieSegment>
        <CookieSegment
          name="rc::d"
          purpose="Used to track and analyze user behavior to distinguish humans from bots or automated software."
          provider="www.google.com"
          service="reCAPTCHA"
          serviceLink="https://policies.google.com/privacy"
          country="United States"
          type="html_local_storage"
          expires="persistent"
        ></CookieSegment>
        <CookieSegment
          name="rc::a"
          purpose="Used to track and analyze user behavior to distinguish humans from bots or automated software."
          provider="www.google.com"
          service="reCAPTCHA"
          serviceLink="https://policies.google.com/privacy"
          country="United States"
          type="html_local_storage"
          expires="persistent"
        ></CookieSegment>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" }, marginTop: "4vh" }}
          className={classes.titleUnderline}
        >
          Analytics and customization cookies:
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          These cookies collect information that is used either in aggregate
          form to help us understand how our Website is being used or how
          effective our marketing campaigns are, or to help us customize our
          Website for you.
        </Typography>
        <CookieSegment
          name="_ga"
          purpose="Records a particular ID used to come up with data about website usage by the user."
          provider="linkriver.io"
          service="Google Analytics"
          serviceLink="https://policies.google.com/privacy"
          country="France"
          type="http_cookie"
          expires="1 year 1 month 4 days"
        ></CookieSegment>
        <CookieSegment
          name="_ga_#"
          purpose="Used to distinguish individual users by means of designation of a randomly generated number as client identifier, which allows calculation of visits and sessions."
          provider="linkriver.io"
          service="Google Analytics"
          serviceLink="https://policies.google.com/privacy"
          country="France"
          type="http_cookie"
          expires="1 year 1 month 4 days"
        ></CookieSegment>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" }, marginTop: "4vh" }}
          className={classes.titleUnderline}
        >
          Unclassified cookies:
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          These are cookies that have not yet been categorized. We are in the
          process of classifying these cookies with the help of their providers.
        </Typography>
        <CookieSegment
          name="Priority"
          purpose="-"
          provider="www.google.com"
          service="-"
          country="United States"
          type="server_cookie"
          expires="session"
        ></CookieSegment>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          How can I control cookies on my browser?
        </Typography>
        <Typography
          sx={{
            typography: { sm: "body1", xs: "body5" },
            marginBottom: "2vh",
          }}
        >
          As the means by which you can refuse cookies through your web browser
          controls vary from browser to browser, you should visit your browser's
          help menu for more information. The following is information about how
          to manage cookies on the most popular browsers:
        </Typography>
        <ul className={classes.list}>
          <li>
            <a
              className={classes.link}
              href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
              rel="noopener noreferrer"
              target="_blank"
            >
              Chrome
            </a>
          </li>
          <li>
            <a
              className={classes.link}
              href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              rel="noopener noreferrer"
              target="_blank"
            >
              Internet Explorer
            </a>
          </li>
          <li>
            <a
              className={classes.link}
              href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
              rel="noopener noreferrer"
              target="_blank"
            >
              Firefox
            </a>
          </li>
          <li>
            <a
              className={classes.link}
              href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
              rel="noopener noreferrer"
              target="_blank"
            >
              Safari
            </a>
          </li>
          <li>
            <a
              className={classes.link}
              href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dds"
              rel="noopener noreferrer"
              target="_blank"
            >
              Edge
            </a>
          </li>
          <li>
            <a
              className={classes.link}
              href="https://help.opera.com/en/latest/web-preferences/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Opera
            </a>
          </li>
        </ul>
        <Typography
          sx={{
            typography: { sm: "body1", xs: "body5" },
            marginBottom: "2vh",
          }}
        >
          In addition, most advertising networks offer you a way to opt out of
          targeted advertising. If you would like to find out more information,
          please visit:
        </Typography>
        <ul className={classes.list}>
          <li>
            <a
              className={classes.link}
              href="http://www.aboutads.info/choices/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Digital Advertising Alliance
            </a>
          </li>
          <li>
            <a
              className={classes.link}
              href="https://youradchoices.ca/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Digital Advertising Alliance of Canada
            </a>
          </li>
          <li>
            <a
              className={classes.link}
              href="http://www.youronlinechoices.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              European Interactive Digital Advertising Alliance
            </a>
          </li>
        </ul>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" }, marginTop: "4vh" }}
          className={classes.title}
        >
          What about other tracking technologies, like web beacons?
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          Cookies are not the only way to recognize or track visitors to a
          website. We may use other, similar technologies from time to time,
          like web beacons (sometimes called "tracking pixels" or "clear gifs").
          These are tiny graphics files that contain a unique identifier that
          enables us to recognize when someone has visited our Website or opened
          an email including them. This allows us, for example, to monitor the
          traffic patterns of users from one page within a website to another,
          to deliver or communicate with cookies, to understand whether you have
          come to the website from an online advertisement displayed on a
          third-party website, to improve site performance, and to measure the
          success of email marketing campaigns. In many instances, these
          technologies are reliant on cookies to function properly, and so
          declining cookies will impair their functioning.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Do you use Flash cookies or Local Shared Objects?
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          Websites may also use so-called "Flash Cookies" (also known as Local
          Shared Objects or "LSOs") to, among other things, collect and store
          information about your use of our services, fraud prevention, and for
          other site operations.
          <br></br>
          <br></br>
          If you do not want Flash Cookies stored on your computer, you can
          adjust the settings of your Flash player to block Flash Cookies
          storage using the tools contained in the{" "}
          <a
            className={classes.link}
            href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
            target="_blank"
            rel="noreferrer"
          >
            Website Storage Settings Panel
          </a>
          . You can also control Flash Cookies by going to the{" "}
          <a
            className={classes.link}
            href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
            target="_blank"
            rel="noreferrer"
          >
            Global Storage Settings Panel
          </a>
          and following the instructions (which may include instructions that
          explain, for example, how to delete existing Flash Cookies (referred
          to "information" on the Macromedia site), how to prevent Flash LSOs
          from being placed on your computer without your being asked, and (for
          Flash Player 8 and later) how to block Flash Cookies that are not
          being delivered by the operator of the page you are on at the time).
          <br></br>
          <br></br>
          Please note that setting the Flash Player to restrict or limit
          acceptance of Flash Cookies may reduce or impede the functionality of
          some Flash applications, including, potentially, Flash applications
          used in connection with our services or online content.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Do you serve targeted advertising?
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          Third parties may serve cookies on your computer or mobile device to
          serve advertising through our Website. These companies may use
          information about your visits to this and other websites in order to
          provide relevant advertisements about goods and services that you may
          be interested in. They may also employ technology that is used to
          measure the effectiveness of advertisements. They can accomplish this
          by using cookies or web beacons to collect information about your
          visits to this and other sites in order to provide relevant
          advertisements about goods and services of potential interest to you.
          The information collected through this process does not enable us or
          them to identify your name, contact details, or other details that
          directly identify you unless you choose to provide these.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          How often will you update this Cookie Policy?
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          We may update this Cookie Policy from time to time in order to
          reflect, for example, changes to the cookies we use or for other
          operational, legal, or regulatory reasons. Please therefore revisit
          this Cookie Policy regularly to stay informed about our use of cookies
          and related technologies.
          <br></br>
          <br></br>
          The date at the top of this Cookie Policy indicates when it was last
          updated.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Where can I get further information?
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          If you have any questions about our use of cookies or other
          technologies, please email us at privacy@linkriver.io or by post to:
          <br></br>
          <br></br>
          LinkRiver LLC
          <br></br>
          Vazha Pshavela Ave, T'bilisi, Georgia
          <br></br>
          Tbilisi
          <br></br>
          Georgia
        </Typography>
      </div>
    </>
  );
};
