import React from "react";
import { useTheme } from "@mui/material/styles";
import { LandingSection } from "../../../../shared/landing-section/LandingSection";
import { HowItWorksSection } from "../sections/how-it-works-section/HowItWorksSection";
import { BenefitsSection } from "../sections/benefits-section/BenefitsSection";
import { ContributorsSection } from "../sections/contributors-section/ContributorsSection";
import { LogoSection } from "../sections/logo-section/LogoSection";
import MetaTags from "react-meta-tags";

interface ValidatorProps {}

export const Validator: React.FC<ValidatorProps> = () => {
  const { palette } = useTheme();
  const gradient = palette.gradient3;

  return (
    <>
      <MetaTags>
        <title>
          Consulting: Scale your blockchain validations with Chainlink
        </title>
        <meta
          name="description"
          content="Discover how to start validating for the most profitable networks with Chainlink. Benefit from the expertise of our consulting service for validators."
        />
        <meta name="robots" content="index, follow" />
        <meta name="robots" content="max-image-preview:large" />
        <link
          rel="canonical"
          href="https://linkriver.io/blockchain-validator-consulting"
        />
      </MetaTags>
      <LandingSection
        gradient={gradient}
        title="Scale with the leading Web3 services platform: Chainlink"
        subTitle="Start validating data for the most profitable networks with one integration, while never taking crucial upfront risks."
        height="70vh"
      ></LandingSection>
      <HowItWorksSection
        description="We are at the forefront of Chainlink’s integrated networks and love to share our expertise with you."
        first={{
          image: "consulting-v-first",
          title: "Check In",
          subTitle:
            "We understand your needs to guarantee the most valuable consulting.",
        }}
        second={{
          image: "consulting-v-second",
          title: "Solution",
          subTitle:
            "We evaluate your current operations and provide practical guidance. ",
        }}
        third={{
          image: "consulting-v-third",
          title: "Moon",
          subTitle:
            "Unleash your potential by launching the rocket into the Chainlink universe.",
        }}
      />
      <BenefitsSection
        primaryBenefits={[
          <>
            <strong>
              Not getting pushed out of the business by other validators
            </strong>{" "}
            by building a better reputation and external representation
          </>,
          <>
            <strong>
              Avoiding the dependence on single-sided income streams
            </strong>{" "}
            by expanding your supported networks as a Chainlink node operator
            granting you stable revenue
          </>,
          <>
            <strong>Reducing the tedious search for new networks</strong>{" "}
            associated with high risks such as upfront payments, long lock-up
            periods and lack of utilization by benefiting from Chainlink's
            selection processes
          </>,
          <>
            Getting insights into why operating Chainlink nodes is the supreme
            discipline among Web3 infrastructure services and{" "}
            <strong>how you can get started before it's too late</strong>
          </>,
        ]}
        secondaryBenefits={[
          <>
            Being a Chainlink node operator is a complex discipline:{" "}
            <strong>
              learn the key differences to running validator nodes
            </strong>{" "}
            and how you can take action now
          </>,
          <>
            <strong>Not missing out of potential delegated assets</strong> by
            making your marketing stand out from the rest
          </>,
          <>
            <strong>
              Hardening your infrastructure to be protected against
              cybersecurity threads
            </strong>
            , electricity and internet outages and implementing strategies to
            achieve a more reliable distribution of your hosting providers
          </>,
          <>
            Learning why you should{" "}
            <strong>never again underestimate the massive impact</strong>{" "}
            Chainlink and its services have on Web3 and beyond
          </>,
        ]}
      />
      <LogoSection
        data={[
          { logo: "consulting-v-logo-first", link: "https://01node.com/" },
          { logo: "consulting-v-logo-second", link: "https://p2p.org/" },
          { logo: "consulting-v-logo-third", link: "https://chorus.one/" },
          {
            logo: "consulting-v-logo-fourth",
            link: "https://www.validationcloud.io/",
          },
        ]}
      />
      <ContributorsSection background="white" />
    </>
  );
};
