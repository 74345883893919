import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  segment: {
    border: "1px solid lightgrey",
    borderRadius: "5px",
    marginBottom: "1vh",
    padding: "16px",

    [theme.breakpoints.up("md")]: {
      ...theme.typography.body1,
    },

    [theme.breakpoints.down("md")]: {
      ...theme.typography.body5,
    },
  },
  item: {
    textAlign: "right",
  },
  description: {
    paddingLeft: "3vw",
  },
  link: {
    textDecoration: "none",
  },
}));

interface CookieSegmentProps {
  name: string;
  purpose: string;
  provider: string;
  service: string;
  serviceLink?: string;
  country: string;
  type: string;
  expires: string;
}

export const CookieSegment: React.FC<CookieSegmentProps> = ({
  name,
  purpose,
  provider,
  service,
  serviceLink,
  country,
  type,
  expires,
}) => {
  const { classes } = useStyles();

  return (
    <Grid className={classes.segment} container>
      <Grid className={classes.item} item xs={3} sm={2} md={1}>
        Name:
      </Grid>
      <Grid className={classes.description} item xs={9} sm={10} md={11}>
        {name}
      </Grid>
      <Grid className={classes.item} item xs={3} sm={2} md={1}>
        Purpose:
      </Grid>
      <Grid className={classes.description} item xs={9} sm={10} md={11}>
        {purpose}
      </Grid>
      <Grid className={classes.item} item xs={3} sm={2} md={1}>
        Provider:
      </Grid>
      <Grid className={classes.description} item xs={9} sm={10} md={11}>
        {provider}
      </Grid>
      <Grid className={classes.item} item xs={3} sm={2} md={1}>
        Service:
      </Grid>
      <Grid className={classes.description} item xs={9} sm={10} md={11}>
        {service}{" "}
        <a
          className={classes.link}
          href={serviceLink}
          target="_blank"
          rel="noreferrer"
        >
          {serviceLink && "View Service Privacy Policy"}
        </a>
      </Grid>
      <Grid className={classes.item} item xs={3} sm={2} md={1}>
        Country:
      </Grid>
      <Grid className={classes.description} item xs={9} sm={10} md={11}>
        {country}
      </Grid>
      <Grid className={classes.item} item xs={3} sm={2} md={1}>
        Type:
      </Grid>
      <Grid className={classes.description} item xs={9} sm={10} md={11}>
        {type}
      </Grid>
      <Grid className={classes.item} item xs={3} sm={2} md={1}>
        Expires in:
      </Grid>
      <Grid className={classes.description} item xs={9} sm={10} md={11}>
        {expires}
      </Grid>
    </Grid>
  );
};
