import React from "react";
import { makeStyles } from "tss-react/mui";
import { Button, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles()((theme) => ({
  openButton: {
    textTransform: "none",
  },
  text: {
    fontSize: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "22px",
    },
  },
}));

interface OpenButtonProps {
  onClick: () => void;
  transparent: boolean;
}

export const OpenButton: React.FC<OpenButtonProps> = ({
  onClick,
  transparent,
}) => {
  const { classes } = useStyles();

  return (
    <Button
      sx={{
        color: transparent
          ? (theme) => theme.palette.primary.contrastText
          : "#005AAA",
      }}
      onClick={onClick}
      className={classes.openButton}
      endIcon={<MenuIcon />}
    >
      <Typography
        className={classes.text}
        sx={{ paddingBottom: "4px" }}
        variant="button3"
      >
        Menu
      </Typography>
    </Button>
  );
};
